import React, { useState } from "react";
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  TextField,
} from "@mui/material";
import { Booking, BookingStatus, TeamType } from "models/booking.model";
import {
  DataGrid,
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GridColDef,
} from "@mui/x-data-grid";
import { setBookingTeams, useUserPermissions } from "utils/utils";
import GeneralDialogueActions from "components/GeneralDialogueActions";
import dayjs from "dayjs";
import { BaseDialogueProps } from "models/index.model";
import { sec } from "auth/accessToken";
import axios from "axios";
import { useGetBookingsQuery } from "state/api";
import { generateMenuItemsFromArray } from "utils/generateMenuItemsFromArray";
import { bookingStatuses } from "dataAssets/constants";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import ConfirmDialogue from "components/ConfirmDialogue";

interface MergeBookingDialogueProps extends BaseDialogueProps {
  booking: Booking;
}

const MergeBookingDialogue: React.FC<MergeBookingDialogueProps> = ({
  handleClose,
  open,
  booking,
}) => {
  const userPermissions = useUserPermissions();
  const hasExportPermission = userPermissions.includes("read:bookings-export");
  const hasImportPermission = userPermissions.includes("read:bookings-import");
  const [startDate, setStartDate] = useState(dayjs().subtract(30, "day"));
  const [endDate, setEndDate] = useState(dayjs());
  const [bookingStatus, setBookingStatus] = useState<BookingStatus>(
    BookingStatus.Ongoing
  );
  const [bookingTeam, setBookingTeam] = useState<TeamType>(TeamType.All);
  const teams = setBookingTeams(hasExportPermission, hasImportPermission);
  const [selectedBookings, setSelectedBookings] = useState<Booking[]>([]);

  const [openConfirmDeletionDialogue, setOpenConfirmDeletionDialogue] =
    useState(false);

  const handleDateChange = (
    newDate: dayjs.Dayjs | null,
    setDate: React.Dispatch<React.SetStateAction<dayjs.Dayjs>>
  ) => {
    if (newDate) {
      setDate(newDate);
      refetch();
    }
  };
  const handleBookingStatusChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setBookingStatus(e.target.value as BookingStatus);
    refetch();
  };
  const handleBookingTeamChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setBookingTeam(e.target.value as TeamType);
    refetch();
  };

  const {
    data: bookings,
    isLoading,
    refetch,
    isFetching,
  } = useGetBookingsQuery({
    hasExportPermission,
    hasImportPermission,
    dateRange: [startDate.toISOString(), endDate.toISOString()],
    bookingStatus,
    bookingTeam,
    consignor: booking.consignor?._id,
    vesselName: booking.vesselVoyage?.vesselName,
    voyageNumber: booking.vesselVoyage?.voyageNumber,
    portOfLoading: booking.portOfLoading?._id,
    portOfDestination: booking.portOfDestination?._id,
    placeOfDelivery: booking.placeOfDelivery?._id,
  });

  const columns: GridColDef[] = [
    {
      field: "bookingNumber",
      headerName: "Booking Number",
      flex: 0.1,
    },
    {
      field: "consignor",
      headerName: "Shipper",
      flex: 0.4,
      valueGetter: (params) => {
        return params.row.consignor?.name;
      },
    },
    {
      field: "consignee",
      headerName: "Consignee",
      flex: 0.4,
      valueGetter: (params) => {
        return params.row.consignee?.name;
      },
    },
    {
      field: "dateCreated",
      headerName: "Date Created",
      flex: 0.1,
      valueGetter: (params) => {
        return dayjs(params.row.dateCreated).format("DD/MM/YYYY");
      },
    },
    GRID_CHECKBOX_SELECTION_COL_DEF,
  ];

  const onSubmit = async () => {
    try {
      const accessToken = await sec.getAccessTokenSilently()();

      await axios.patch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/bookings/mergeBookings/${booking._id}`,
        {
          mergingBookingIds: selectedBookings,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      handleClose();
      window.location.reload();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Box>
      <Dialog open={open} onClose={() => handleClose()} fullWidth maxWidth="xl">
        <DialogTitle
          sx={{
            fontSize: "1.6rem",
          }}
        >
          Merge Bookings
        </DialogTitle>
        <DialogContent>
          <Box>
            <Typography sx={{ paddingBottom: "1rem" }}>
              {`Select the bookings you want to merge with ${booking.bookingNumber}`}
            </Typography>
            <Box
              display={"flex"}
              alignItems={"center"}
              gap={"1rem"}
              mb={"1rem"}
            >
              <TextField
                sx={{ width: "9rem" }}
                select
                label="Status"
                value={bookingStatus}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleBookingStatusChange(e)
                }
              >
                {generateMenuItemsFromArray(bookingStatuses)}
              </TextField>
              <TextField
                sx={{ width: "9rem" }}
                select
                label="Team"
                value={bookingTeam}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleBookingTeamChange(e)
                }
              >
                {generateMenuItemsFromArray(teams ?? [])}
              </TextField>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  sx={{ width: "9rem" }}
                  label="Start"
                  format="DD/MM/YYYY"
                  value={startDate}
                  onChange={(newDate) =>
                    handleDateChange(newDate, setStartDate)
                  }
                />
                <DatePicker
                  sx={{ width: "9rem" }}
                  label="End"
                  format="DD/MM/YYYY"
                  value={endDate}
                  onChange={(newDate) => handleDateChange(newDate, setEndDate)}
                />
              </LocalizationProvider>
            </Box>
            <Box height={"60vh"}>
              <DataGrid
                checkboxSelection
                hideFooter
                loading={isLoading || isFetching}
                rows={bookings?.filter((b) => b._id !== booking._id) ?? []}
                getRowId={(row) => row._id}
                columns={columns}
                onRowSelectionModelChange={(newSelection: any) => {
                  setSelectedBookings(newSelection);
                }}
              />
            </Box>
          </Box>
        </DialogContent>
        <GeneralDialogueActions
          onClick={() => setOpenConfirmDeletionDialogue(true)}
          handleClose={handleClose}
        />
      </Dialog>
      <ConfirmDialogue
        open={openConfirmDeletionDialogue}
        handleClose={() => setOpenConfirmDeletionDialogue(false)}
        title="Confirm Merge"
        content={`Are you sure you want to merge selected bookings with ${booking.bookingNumber}?`}
        onConfirm={onSubmit}
      />
    </Box>
  );
};

export default MergeBookingDialogue;
