import React, { useEffect, useState } from "react";
import axios from "axios";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import {
  Box,
  TextField,
  Dialog,
  DialogContent,
  DialogTitle,
  Autocomplete,
  InputAdornment,
} from "@mui/material";
import { sec } from "../../auth/accessToken";
import { useGetVendorsQuery } from "state/api";
import { vendorTypes } from "../../dataAssets/constants";
import { generateMenuItemsFromArray } from "../../utils/generateMenuItemsFromArray";
import { BaseDialogueProps, Vendor, VendorType } from "models/index.model";
import AlertSnackbar from "components/AlertSnackbar";
import GeneralDialogueActions from "components/GeneralDialogueActions";
import { uniqueNameRefinement } from "utils/utils";
import countryList from "dataAssets/countryList";

export interface VendorDialogueProps extends BaseDialogueProps {
  vendorToUpdate?: Vendor;
}

const CreateVendorDialogue: React.FC<VendorDialogueProps> = ({
  handleClose,
  open,
  vendorToUpdate,
}) => {
  const isEditing = !!vendorToUpdate?._id;

  const { data: vendors } = useGetVendorsQuery();

  const vendorCreationSchema = z.object({
    name: z
      .string()
      .min(1, { message: "Name is required" })
      .refine(uniqueNameRefinement(vendors, vendorToUpdate, "name"), {
        message: "A vendor with that name already exists",
      }),
    contactPerson: z.string(),
    phoneNumber: z.string(),
    email: z.string(),
    address: z.string().min(1, { message: "Address is required" }),
    city: z.string().min(1, { message: "City is required" }),
    postcode: z.string().min(1, { message: "Postcode is required" }),
    country: z.string().min(1, { message: "Country is required" }),
    vendorType: z.string().min(1, { message: "Vendor Type is required" }),
    creditPeriod: z.string().min(1, { message: "Credit Period is required" }),
    creditLimit: z.string().min(1, { message: "Credit Limit is required" }),
  });

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm<Vendor>({
    resolver: zodResolver(vendorCreationSchema),
  });

  useEffect(() => {
    reset(vendorToUpdate);
  }, [vendorToUpdate, reset]);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const handleSnackbarClose = (event: any, reason: string) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbarOpen(false);
  };

  const onSubmit = async (data: Vendor) => {
    try {
      const accessToken = await sec.getAccessTokenSilently()();
      let apiUrl = `${process.env.REACT_APP_BASE_URL}/api/v1/vendors/`;

      if (isEditing) {
        apiUrl += vendorToUpdate._id;
        await axios.patch(apiUrl, data, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
      } else {
        await axios.post(apiUrl, data, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
      }

      setSnackbarOpen(true);
      handleClose();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Box>
      <Dialog open={open} onClose={() => handleClose()} fullWidth maxWidth="md">
        <DialogTitle
          sx={{
            fontSize: "1.6rem",
          }}
        >
          {isEditing ? "Edit Vendor" : "Create Vendor"}
        </DialogTitle>
        <DialogContent>
          <form>
            <Box
              display={"grid"}
              gridTemplateColumns={"1fr 1fr"}
              gap={"0.5rem"}
            >
              <TextField
                label="Name *"
                autoComplete="off"
                variant="filled"
                {...register("name")}
                error={!!errors.name}
                helperText={errors.name?.message}
              />
              <TextField
                label="Contact Person "
                autoComplete="off"
                variant="filled"
                {...register("contactPerson")}
              />
              <TextField
                label="Phone Number "
                autoComplete="off"
                variant="filled"
                {...register("phoneNumber")}
              />
              <TextField
                label="Email "
                autoComplete="off"
                variant="filled"
                {...register("email")}
                error={!!errors.email}
                helperText={errors.email?.message}
              />
              <TextField
                label="Address *"
                autoComplete="off"
                variant="filled"
                {...register("address")}
                error={!!errors.address}
                helperText={errors.address?.message}
                sx={{ gridColumn: "1 / 3" }}
              />
              <TextField
                label="City *"
                autoComplete="off"
                variant="filled"
                {...register("city")}
                error={!!errors.city}
                helperText={errors.city?.message}
              />
              <TextField
                label="Postcode *"
                autoComplete="off"
                variant="filled"
                {...register("postcode")}
                error={!!errors.postcode}
                helperText={errors.postcode?.message}
              />
              <Controller
                name="country"
                control={control}
                defaultValue="United Kingdom"
                render={({ field: { value, onChange } }) => (
                  <Autocomplete
                    disablePortal
                    options={countryList}
                    value={value}
                    onChange={(_, value) => {
                      onChange(value);
                    }}
                    isOptionEqualToValue={(option, value) => {
                      return option.toLowerCase() === value.toLowerCase();
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Country *"
                        autoComplete="off"
                        variant="filled"
                        error={!!errors.country}
                        helperText={errors.country?.message}
                      />
                    )}
                  />
                )}
              />
              <Controller
                name="vendorType"
                control={control}
                defaultValue={VendorType.Carrier}
                render={({ field }) => (
                  <TextField
                    {...field}
                    select
                    label="Vendor Type *"
                    value={field.value}
                    autoComplete="off"
                    variant="filled"
                  >
                    {generateMenuItemsFromArray(vendorTypes)}
                  </TextField>
                )}
              />
              <TextField
                label="Credit Period (Days) * "
                autoComplete="off"
                variant="filled"
                type="number"
                {...register("creditPeriod")}
                error={!!errors.creditPeriod}
                helperText={errors.creditPeriod?.message}
              />
              <TextField
                label="Credit Limit * "
                autoComplete="off"
                variant="filled"
                type="number"
                {...register("creditLimit")}
                error={!!errors.creditLimit}
                helperText={errors.creditLimit?.message}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">£</InputAdornment>
                  ),
                }}
              />
            </Box>
          </form>
        </DialogContent>
        <GeneralDialogueActions
          onClick={handleSubmit(onSubmit)}
          handleClose={handleClose}
          submitText={isEditing ? "Update" : "Create"}
        />
      </Dialog>
      <AlertSnackbar
        open={snackbarOpen}
        handleClose={handleSnackbarClose}
        severity={"success"}
        message={isEditing ? "Vendor updated" : "Vendor created"}
      />
    </Box>
  );
};

export default CreateVendorDialogue;
