import React from "react";
import axios from "axios";
import { useForm, useFieldArray } from "react-hook-form";
import {
  Box,
  Button,
  useTheme,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
} from "@mui/material";
import { AddOutlined, RemoveOutlined } from "@mui/icons-material";
import { sec } from "../../auth/accessToken";
import { BaseDialogueProps } from "models/index.model";
import { Booking } from "models/booking.model";
import GeneralDialogueActions from "components/GeneralDialogueActions";
import { useGetAuditsQuery } from "state/api";

interface UpdateRemarksDialogueProps extends BaseDialogueProps {
  booking: Booking;
}

//todo: test this once it is in use somewhere

const UpdateRemarksDialogue: React.FC<UpdateRemarksDialogueProps> = ({
  handleClose,
  open,
  booking,
}) => {
  const theme = useTheme();

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      remarks: booking.remarks ?? [],
    },
  });

  const {
    fields: remarksFields,
    append: remarksAppend,
    remove: remarksRemove,
  } = useFieldArray({
    control,
    name: "remarks",
  });

  const { refetch: auditLogsRefetch } = useGetAuditsQuery([
    "Booking",
    booking._id,
  ]);

  const onSubmit = async (data: any) => {
    try {
      const accessToken = await sec.getAccessTokenSilently()();

      await axios.patch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/bookings/${booking._id}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      handleClose();
      auditLogsRefetch();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Box>
      <Dialog open={open} onClose={() => handleClose()} fullWidth maxWidth="md">
        <DialogTitle
          sx={{
            fontSize: "1.6rem",
          }}
        >
          Add Remarks
        </DialogTitle>
        <DialogContent>
          <form>
            <Box mb="1rem">
              <Button
                variant="contained"
                type="button"
                startIcon={<AddOutlined />}
                onClick={() => remarksAppend({ remark: "" })}
                sx={{
                  bgcolor: theme.palette.secondary[400],
                  mb: "1rem",
                  "&:hover": {
                    backgroundColor: theme.palette.secondary[500],
                  },
                }}
              >
                Add Remark
              </Button>
            </Box>
            {remarksFields.map((item, index) => {
              return (
                <Box
                  key={index}
                  display={"grid"}
                  gridTemplateColumns={"14fr 1fr"}
                  alignItems={"center"}
                  gap={"1rem"}
                  mb={"1rem"}
                >
                  <TextField
                    label="Remarks"
                    autoComplete="off"
                    fullWidth
                    {...register(`remarks.${index}.remark`, {
                      required: true,
                    })}
                    error={!!errors?.remarks?.[index]?.remark}
                    helperText={errors?.remarks?.[index]?.remark?.message}
                  />
                  <Box>
                    <IconButton
                      type="button"
                      onClick={() => remarksRemove(index)}
                      sx={{
                        bgcolor: theme.palette.secondary[400],
                        "&:hover": {
                          backgroundColor: theme.palette.secondary[500],
                        },
                      }}
                    >
                      <RemoveOutlined />
                    </IconButton>
                  </Box>
                </Box>
              );
            })}
          </form>
        </DialogContent>
        <GeneralDialogueActions
          onClick={handleSubmit(onSubmit)}
          handleClose={handleClose}
          submitText="Update"
        />
      </Dialog>
    </Box>
  );
};

export default UpdateRemarksDialogue;
