import React, { useState } from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Box,
  useTheme,
  IconButton,
  Typography,
  Tooltip,
  Divider,
} from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { AdditionalCharge, Charge } from "models/booking.model";
import { setDialogueState } from "utils/utils";
import UpdateAdditionalChargesDialogue from "components/UpdateAdditionalChargesDialogue";

interface AdditionalChargesTableProps {
  id: string;
  additionalBuyRates: Charge[] | undefined;
  additionalSellRates: AdditionalCharge[] | undefined;
  refetch: () => void;
}

const AdditionalChargesTable: React.FC<AdditionalChargesTableProps> = ({
  id,
  additionalBuyRates,
  additionalSellRates,
  refetch,
}) => {
  const theme = useTheme();

  const [openUpdateAdditionalCharges, setOpenUpdateAdditionalCharges] =
    useState(false);
  return (
    <Box>
      <Box>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Typography
            sx={{
              letterSpacing: "0.8px",
            }}
          >
            Additional Charges
          </Typography>
          <Box>
            <Tooltip title="Edit Additional Charges">
              <IconButton
                onClick={() =>
                  setDialogueState(true, setOpenUpdateAdditionalCharges)
                }
              >
                <EditOutlinedIcon />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
      </Box>
      <Table
        size="small"
        sx={{
          "& .MuiTableCell-root": {
            borderBottom: "none",
            color: theme.palette.secondary[100],
          },
          "& .MuiTableHead-root .MuiTableCell-root": {
            fontWeight: "600",
          },
        }}
      >
        <TableHead>
          <TableRow>
            <TableCell>Charge Name</TableCell>
            <TableCell>Currency</TableCell>
            <TableCell>Rate</TableCell>
            <TableCell>Base</TableCell>
            <TableCell>Raised</TableCell>
          </TableRow>
        </TableHead>
        <TableRow>
          <TableCell colSpan={5}>
            <Divider>
              <Typography variant="caption">
                {"Additional Buy Rates"}
              </Typography>
            </Divider>
          </TableCell>
        </TableRow>
        <TableBody>
          {additionalBuyRates?.map((charge) => (
            <TableRow key={charge._id}>
              <TableCell>{charge.chargeName}</TableCell>
              <TableCell>{charge.currency}</TableCell>
              <TableCell>{charge.rate}</TableCell>
              <TableCell>{charge.base}</TableCell>
              <TableCell>{"N/A"}</TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableRow>
          <TableCell colSpan={5}>
            <Divider>
              <Typography variant="caption">
                {"Additional Sell Rates"}
              </Typography>
            </Divider>
          </TableCell>
        </TableRow>
        <TableBody>
          {additionalSellRates?.map((charge) => (
            <TableRow key={charge._id}>
              <TableCell>{charge.chargeName}</TableCell>
              <TableCell>{charge.currency}</TableCell>
              <TableCell>{charge.rate}</TableCell>
              <TableCell>{charge.base}</TableCell>
              <TableCell>{charge.raised ? "Yes" : "No"}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <UpdateAdditionalChargesDialogue
        open={openUpdateAdditionalCharges}
        handleClose={() =>
          setDialogueState(false, setOpenUpdateAdditionalCharges, refetch)
        }
        id={id}
        additionalBuyRates={additionalBuyRates}
        additionalSellRates={additionalSellRates}
      />
    </Box>
  );
};

export default AdditionalChargesTable;
