import React, { useReducer, useState } from "react";
import { GridColDef } from "@mui/x-data-grid";
import { AddOutlined } from "@mui/icons-material";
import Header from "../../components/Header";
import { useGetAuditsQuery, useGetCustomersQuery } from "../../state/api";
import { Box, Button, useTheme } from "@mui/material";
import CreateCustomerDialogue from "../../components/CreateCustomerDialogue";
import { Customer } from "models/index.model";
import { initialState, reducer } from "../../utils/dialogState";
import { useUserPermissions } from "utils/utils";
import { generateActionsColumn } from "utils/generateActionsColumn";
import axios from "axios";
import { sec } from "auth/accessToken";
import CustomDataGrid from "components/CustomDataGrid";
import AuditLogsDialogue from "components/AuditLogsDialogue";

const Customers = () => {
  const theme = useTheme();

  const userPermissions = useUserPermissions();
  const hasCreateMasterDataPermission =
    userPermissions.includes("create:masterdata");
  const hasUpdateMasterDataPermission =
    userPermissions.includes("update:masterdata");
  const hasDeleteMasterDataPermission =
    userPermissions.includes("delete:masterdata");

  const [{ open, entityToUpdate }, dispatch] = useReducer(
    reducer<Customer>,
    initialState<Customer>()
  );

  const handleClickOpenCreate = () => {
    dispatch({ type: "OPEN_DIALOG", payload: {} as Customer });
  };

  const handleClickOpenEdit = (customer: Customer) => {
    dispatch({ type: "OPEN_DIALOG", payload: customer });
  };

  const handleClose = () => {
    dispatch({ type: "CLOSE_DIALOG" });
    refetch();
  };

  const [openConfirmDialogue, setOpenConfirmDialogue] = useState(false);
  const [openAuditDialogue, setOpenAuditDialogue] = useState(false);
  const [currentMasterDataId, setCurrentMasterDataId] = useState<string>("");

  const { data: customers, isLoading, refetch } = useGetCustomersQuery();
  const { data: auditLogs } = useGetAuditsQuery([
    "Customer",
    currentMasterDataId,
  ]);

  const deleteCustomer = async (customerId: string) => {
    try {
      const accessToken = await sec.getAccessTokenSilently()();

      await axios.delete(
        `${process.env.REACT_APP_BASE_URL}/api/v1/customers/${customerId}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      refetch();
    } catch (error) {
      console.error(error);
    }
  };

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Name",
      flex: 0.6,
    },
    {
      field: "address",
      headerName: "Address",
      flex: 1,
      valueGetter(params) {
        return `${params.row.address}, ${params.row.city}, ${params.row.postcode}, ${params.row.country}`;
      },
    },
    {
      field: "contactPerson",
      headerName: "Contact Person",
      flex: 0.3,
    },
    {
      field: "phoneNumber",
      headerName: "Phone Number",
      flex: 0.3,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 0.4,
    },
    {
      field: "creditPeriod",
      headerName: "Credit Period",
      flex: 0.2,
    },
    {
      field: "creditLimit",
      headerName: "Credit Limit (£)",
      flex: 0.2,
    },
    {
      field: "EORI",
      headerName: "EORI",
      flex: 0.3,
    },
  ];

  if (hasUpdateMasterDataPermission || hasDeleteMasterDataPermission) {
    columns.push(
      generateActionsColumn<Customer>(
        hasUpdateMasterDataPermission,
        hasDeleteMasterDataPermission,
        handleClickOpenEdit,
        setOpenAuditDialogue,
        deleteCustomer,
        openConfirmDialogue,
        setOpenConfirmDialogue,
        currentMasterDataId,
        setCurrentMasterDataId
      )
    );
  }

  return (
    <Box m="1.5rem 2.5rem">
      <Box display={"flex"} justifyContent={"space-between"}>
        <Header title="Customers" />
        <Box>
          {hasCreateMasterDataPermission && (
            <Button
              variant="contained"
              startIcon={<AddOutlined />}
              onClick={handleClickOpenCreate}
              sx={{
                bgcolor: theme.palette.secondary[400],
                "&:hover": {
                  backgroundColor: theme.palette.secondary[500],
                },
              }}
            >
              Create Customer
            </Button>
          )}
          <CreateCustomerDialogue
            handleClose={handleClose}
            open={open}
            customerToUpdate={entityToUpdate}
          />
        </Box>
      </Box>
      <CustomDataGrid
        data={customers}
        columns={columns}
        isLoading={isLoading}
        sortModel={[{ field: "name", sort: "asc" }]}
      />
      <AuditLogsDialogue
        open={openAuditDialogue}
        handleClose={() => setOpenAuditDialogue(false)}
        auditLogs={auditLogs}
      />
    </Box>
  );
};

export default Customers;
