import React, { useState } from "react";
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
} from "@mui/material";
import {
  Booking,
  DepotSite,
  TransportSchedule,
  TransportSite,
} from "models/booking.model";
import { useForm } from "react-hook-form";
import {
  DataGrid,
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GridColDef,
} from "@mui/x-data-grid";
import { getLabelForBookingDetail, useUserPermissions } from "utils/utils";
import BookingDialogueContent from "components/BookingDialogueContent";
import GeneralDialogueActions from "components/GeneralDialogueActions";
import dayjs from "dayjs";
import { BaseDialogueProps } from "models/index.model";
import { sec } from "auth/accessToken";
import axios from "axios";

interface SplitBookingDialogueProps extends BaseDialogueProps {
  booking: Booking;
}

const SplitBookingDialogue: React.FC<SplitBookingDialogueProps> = ({
  handleClose,
  open,
  booking,
}) => {
  const userPermissions = useUserPermissions();
  const hasExportPermission = userPermissions.includes(
    "create:bookings-export"
  );
  const hasImportPermission = userPermissions.includes(
    "create:bookings-import"
  );
  let [pageNumber, setPageNumber] = useState(1);
  const [editableRows, setEditableRowSelection] = React.useState<string[]>([]);
  const [selectedLoadSchedules, setSelectedLoadSchedules] = React.useState<
    string[]
  >([]);
  const maxPages = 3;

  const useFormReference = useForm<Booking>({
    defaultValues: booking,
  });

  const editableFields = {
    consignor: "Consignor",
    consignee: "Consignee",
    carrier: "Carrier",
    carrierQuotationReference: "Carrier Quotation Reference",
    blremarks: "BL Remarks",
    agent: "Agent",
    purchaseOrderNumber: "Purchase Order Number",
    releasePin: "Release Pin",
    portOfLoading: "Port of Loading",
    portOfDestination: "Port of Destination",
    placeOfDelivery: "Place of Delivery",
    containerType: "Container Type",
    packages: "Packages",
    cargoDescription: "Cargo Description",
    cargoValue: "Cargo Value",
    HSCode: "HS Code",
    vesselVoyage: "Vessel Voyage",
    loadingTypeExport: "Export Loading Type",
    loadingTypeImport: "Import Loading Type",
    doorFacing: "Door Facing",
    etd: "ETD",
    eta: "ETA",
    etaPOL: "ETA(POL)",
  };

  const editableFieldsColumDef: GridColDef[] = [
    {
      field: "fieldName",
      headerName: "Field",
      flex: 0.3,
    },
    {
      field: "currentValue",
      headerName: "Current Details",
      flex: 0.6,
    },
    GRID_CHECKBOX_SELECTION_COL_DEF,
  ];

  const loadScheduleColumnDef: GridColDef[] = [
    {
      field: "site",
      headerName: "Site",
      flex: 0.3,
    },
    {
      field: "date",
      headerName: "Date",
      valueFormatter: (params) => dayjs(params.value).format("DD-MM-YY"),
      flex: 0.3,
    },
    {
      field: "time",
      headerName: "Time",
      valueFormatter: (params) => dayjs(params.value).format("HH:mm"),
      flex: 0.3,
    },
    {
      field: "reference",
      headerName: "Reference",
      flex: 0.3,
    },
    {
      field: "containerNumber",
      headerName: "Container Number",
      flex: 0.6,
    },
    {
      field: "sealNumber",
      headerName: "Seal Number",
      flex: 0.6,
    },
    {
      field: "weight",
      headerName: "Weight",
      flex: 0.6,
    },
    {
      field: "hauler",
      headerName: "Hauler",
      flex: 0.6,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 0.6,
    },
    GRID_CHECKBOX_SELECTION_COL_DEF,
  ];

  const subTitleStyles = {
    paddingBottom: "10px",
  };

  const { handleSubmit } = useFormReference;

  const splitTransportSites = (transportSchedule: TransportSchedule[]) => {
    const updatedLoadSites = booking.transportSites?.map((site) => {
      const filteredSchedule = transportSchedule.filter(
        (schedule) => schedule.site === site.site?.city
      );
      const newSite: TransportSite = {
        site: site.site,
        containers: filteredSchedule.length,
      };
      return newSite;
    });
    return updatedLoadSites?.filter((site) => site.containers !== 0);
  };

  const splitDepots = (
    depots: DepotSite[] | undefined,
    transportSchedule: TransportSchedule[]
  ) => {
    const updatedDepots = depots?.map((depot) => {
      const newDepot: DepotSite = {
        site: depot.site,
        containers: transportSchedule.length,
        reference: depot.reference,
      };
      return newDepot;
    });
    return updatedDepots;
  };

  const onSubmit = async (bookingData: Booking) => {
    if (pageNumber === maxPages) {
      const updatedLoadSchedule: TransportSchedule[] = [];
      const newLoadSchedule: TransportSchedule[] = [];
      booking.transportSchedule?.forEach((loadSchedule) => {
        if (!loadSchedule._id) {
          return;
        }
        if (selectedLoadSchedules.includes(loadSchedule._id)) {
          newLoadSchedule.push(loadSchedule);
        } else {
          updatedLoadSchedule.push(loadSchedule);
        }
      });

      const updatedBooking: Booking = {
        ...booking,
        numberOfContainers:
          booking.numberOfContainers - selectedLoadSchedules.length,
        transportSites: splitTransportSites(updatedLoadSchedule),
        transportSchedule: updatedLoadSchedule,
        pickupDepot: splitDepots(booking.pickupDepot, updatedLoadSchedule),
        dropoffDepot: splitDepots(booking.dropoffDepot, updatedLoadSchedule),
      };

      const newBooking: Partial<Booking> = {
        ...bookingData,
        numberOfContainers: selectedLoadSchedules.length,
        transportSites: splitTransportSites(newLoadSchedule),
        transportSchedule: newLoadSchedule,
        pickupDepot: splitDepots(booking.pickupDepot, newLoadSchedule),
        dropoffDepot: splitDepots(booking.dropoffDepot, newLoadSchedule),
      };

      (
        [
          "_id",
          "bookingNumber",
          "carrierBookingNumber",
          "mblNumber",
          "hblStatus",
          "salesInvoices",
          "purchaseInvoices",
          "creditNotes",
          "debitNotes",
          "stepsCompleted",
          "deadlines",
          "deadlineTimes",
          "dateCreated",
        ] as (keyof Booking)[]
      ).forEach((key) => {
        delete newBooking[key];
      });

      const accessToken = await sec.getAccessTokenSilently()();

      await axios.patch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/bookings/${booking._id}`,
        updatedBooking,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/v1/bookings/`,
        newBooking,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      setPageNumber(1);
      handleClose();
      window.location.reload();
    } else {
      setPageNumber(pageNumber + 1);
    }
  };
  return (
    <Box>
      <Dialog open={open} onClose={() => handleClose()} fullWidth maxWidth="xl">
        <DialogTitle
          sx={{
            fontSize: "1.6rem",
          }}
        >
          {`Split Booking (${pageNumber}/${maxPages})`}
        </DialogTitle>
        <DialogContent>
          {pageNumber === 1 && (
            <Box>
              <Typography sx={subTitleStyles}>
                {
                  "Select the fields that you want to change for the new booking (These will remain unchanged in the original)"
                }
              </Typography>
              <DataGrid
                checkboxSelection
                hideFooter
                getRowId={(row) => row.id}
                rows={Object.entries(editableFields).map(([field, label]) => {
                  const key = field as keyof Booking;
                  return {
                    id: key,
                    fieldName: label,
                    currentValue:
                      getLabelForBookingDetail(booking[key], key) || "-",
                  };
                })}
                columns={editableFieldsColumDef}
                onRowSelectionModelChange={(newSelection: any) => {
                  setEditableRowSelection(newSelection);
                }}
                rowSelectionModel={editableRows}
              />
            </Box>
          )}
          {pageNumber === 2 && (
            <Box>
              <Typography sx={subTitleStyles}>
                {"Update the details for the selected fields"}
              </Typography>
              <BookingDialogueContent
                bookingToUpdate={booking}
                useFormReference={useFormReference}
                hasExportPermission={hasExportPermission}
                hasImportPermission={hasImportPermission}
                disabledFields={
                  new Set([
                    ...Object.keys(editableFields).filter(
                      (field) => !editableRows.includes(field)
                    ),
                    "numberOfContainers",
                    "approximateWeight",
                    "carrierBookingNumber",
                    "mblNumber",
                    "mblStatus",
                    "hblNumber",
                    "hblStatus",
                  ])
                }
              />
            </Box>
          )}
          {pageNumber === 3 && (
            <Box>
              <Typography sx={subTitleStyles}>
                {
                  "Select the containers you want to move to the new booking. (Unselected Containers will remain with the original Booking)"
                }
              </Typography>
              <DataGrid
                checkboxSelection
                hideFooter
                getRowId={(row) => row._id}
                rows={booking.transportSchedule ?? []}
                columns={loadScheduleColumnDef}
                onRowSelectionModelChange={(newSelection: any) => {
                  setSelectedLoadSchedules(newSelection);
                }}
                rowSelectionModel={selectedLoadSchedules}
              />
            </Box>
          )}
        </DialogContent>
        <GeneralDialogueActions
          onClick={handleSubmit(onSubmit)}
          handleClose={handleClose}
          setPageNumber={setPageNumber}
          pageNumber={pageNumber}
          maxPages={maxPages}
        />
      </Dialog>
    </Box>
  );
};

export default SplitBookingDialogue;
