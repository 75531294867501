import React, { useState } from "react";
import { GridColDef, GridColumnVisibilityModel } from "@mui/x-data-grid";
import { useGetBookingsQuery } from "../../state/api";
import Header from "../../components/Header";
import { Box, useTheme, IconButton, Tooltip } from "@mui/material";
import { useNavigate, generatePath } from "react-router-dom";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import dayjs from "dayjs";
import {
  handleSnackbarClose,
  setBookingTeams,
  useUserPermissions,
} from "utils/utils";
import { TransportSchedule, TransportSite } from "models/booking.model";
import CustomDataGrid from "components/CustomDataGrid";
import BookingsFilterSideBar from "components/BookingsFilterSideBar";
import { useDispatch, useSelector } from "react-redux";
import { setBookingsColumnVisibilityModel } from "state";
import {
  AddOutlined,
  FilterAltOutlined,
  RefreshOutlined,
  SaveOutlined,
} from "@mui/icons-material";
import { sec } from "auth/accessToken";
import axios from "axios";
import AlertSnackbar from "components/AlertSnackbar";

const Bookings = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const userPermissions = useUserPermissions();
  const hasExportPermission = userPermissions.includes("read:bookings-export");
  const hasImportPermission = userPermissions.includes("read:bookings-import");
  const teams = setBookingTeams(hasExportPermission, hasImportPermission);

  const {
    startDate,
    endDate,
    bookingStatus,
    bookingTeam,
    consignor,
    consignee,
    carrier,
    portOfLoading,
    portOfDestination,
    containerType,
    salesInvoiceCreated,
    purchaseInvoiceCreated,
    blStatus,
    outstandingAdditionalCharges,
  } = useSelector((state: any) => state.global.bookingsFilters);

  const {
    data: bookings,
    isLoading,
    refetch,
    isFetching,
  } = useGetBookingsQuery({
    hasExportPermission,
    hasImportPermission,
    dateRange: [startDate, endDate],
    bookingStatus,
    bookingTeam,
    consignor: consignor?._id,
    consignee: consignee?._id,
    carrier: carrier?._id,
    portOfLoading: portOfLoading?._id,
    portOfDestination: portOfDestination?._id,
    containerType,
    salesInvoiceCreated,
    purchaseInvoiceCreated,
    blStatus,
    outstandingAdditionalCharges,
  });

  const columns: GridColDef[] = [
    {
      field: "bookingNumber",
      headerName: "Booking Number",
      flex: 0.3,
    },
    {
      field: "consignor",
      headerName: "Shipper",
      flex: 0.6,
      valueGetter: (params) => {
        return params.row.consignor?.name;
      },
    },
    {
      field: "consignee",
      headerName: "Consignee",
      flex: 0.6,
      valueGetter: (params) => {
        return params.row.consignee?.name;
      },
    },
    {
      field: "notifyParty",
      headerName: "Notify Party",
      flex: 0.6,
      valueGetter: (params) => {
        return params.row.notifyParty?.name;
      },
    },
    {
      field: "carrier",
      headerName: "Carrier",
      flex: 0.3,
      valueGetter: (params) => {
        return params.row.carrier?.name;
      },
    },
    {
      field: "carrierBookingNumber",
      headerName: "Carrier Booking Number",
      flex: 0.3,
    },
    {
      field: "carrierQuotationReference",
      headerName: "Carrier Quote Ref",
      flex: 0.2,
    },
    {
      field: "purchaseOrderNumber",
      headerName: "PO Number",
      flex: 0.3,
    },
    {
      field: "mblNumber",
      headerName: "MBL",
      flex: 0.3,
    },
    {
      field: "hblNumber",
      headerName: "HBL",
      flex: 0.3,
    },
    { field: "blRemarks", headerName: "BL Remarks", flex: 0.3 },
    {
      field: "blStatus",
      headerName: "BL Status",
      flex: 0.4,
      valueGetter: (params) => {
        if (params.row.blStatusHistory.length > 0) {
          return `${params.row.blStatusHistory.slice(-1).pop()?.status} `;
        }
      },
    },
    {
      field: "blStatusDate",
      headerName: "BL Status Date",
      flex: 0.3,
      valueGetter: (params) => {
        if (params.row.blStatusHistory.slice(-1).pop()?.statusDate) {
          return dayjs(
            params.row.blStatusHistory.slice(-1).pop()?.statusDate
          ).format("DD/MM/YY");
        }
      },
    },
    {
      field: "followUp",
      headerName: "Follow Up",
      flex: 0.3,
      valueGetter: (params) => {
        if (params.row.blStatusHistory.length > 0) {
          return params.row.blStatusHistory.slice(-1).pop()?.followUp;
        }
      },
    },
    {
      field: "followUpDate",
      headerName: "Follow Up Date",
      flex: 0.3,
      valueGetter: (params) => {
        if (params.row.blStatusHistory.slice(-1).pop()?.followUpDate) {
          return dayjs(
            params.row.blStatusHistory.slice(-1).pop()?.followUpDate
          ).format("DD/MM/YY");
        }
      },
    },
    {
      field: "agent",
      headerName: "Agent",
      flex: 0.4,
      valueGetter: (params) => {
        return params.row.agent?.name;
      },
    },
    {
      field: "haulers",
      headerName: "Haulers",
      flex: 0.5,
      valueGetter: (params) => {
        const uniqueHaulers = (
          params.row.transportSchedule as TransportSchedule[]
        ).reduce((acc, transportSchedule) => {
          const hauler = transportSchedule.hauler ?? "N/A";
          if (!acc.includes(hauler)) {
            acc.push(hauler);
          }
          return acc;
        }, [] as string[]);

        return uniqueHaulers.join(", ");
      },
    },
    {
      field: "sites",
      headerName: "Sites",
      flex: 0.3,
      valueGetter: (params) => {
        return (params.row.transportSites as TransportSite[])
          .map((transportSite) => transportSite.site?.city ?? "N/A")
          .join(", ");
      },
    },
    {
      field: "portOfLoading",
      headerName: "POL",
      flex: 0.3,
      valueGetter: (params) => {
        return params.row.portOfLoading?.portName;
      },
    },
    {
      field: "portOfDestination",
      headerName: "POD",
      flex: 0.3,
      valueGetter: (params) => {
        return params.row.portOfDestination?.portName;
      },
    },
    {
      field: "placeOfDelivery",
      headerName: "FPOD",
      flex: 0.3,
      valueGetter: (params) => {
        return params.row.placeOfDelivery?.portName;
      },
    },
    {
      field: "etd",
      headerName: "ETD",
      flex: 0.2,
      valueGetter: (params) => {
        return dayjs(params.row.etd).format("DD/MM/YY");
      },
    },
    {
      field: "eta",
      headerName: "ETA (POD)",
      flex: 0.2,
      valueGetter: (params) => {
        return dayjs(params.row.eta).format("DD/MM/YY");
      },
    },
    {
      field: "etaPOL",
      headerName: "ETA (POL)",
      flex: 0.2,
      valueGetter: (params) => {
        return dayjs(params.row.etaPOL).format("DD/MM/YY");
      },
    },
    {
      field: "quotationApproval",
      headerName: "Quote Approval",
      flex: 0.3,
      valueGetter: (params) => {
        return params.row.quotationApproval ? "Approved" : "Pending";
      },
    },
    {
      field: "numberOfContainers",
      headerName: "CNTS",
      flex: 0.1,
    },
    { field: "packages", headerName: "Packages", flex: 0.2 },
    { field: "approximateWeight", headerName: "Weight", flex: 0.2 },
    { field: "containerType", headerName: "Container Type", flex: 0.3 },
    { field: "doorFacing", headerName: "Door Facing", flex: 0.2 },
    {
      field: "releasePin",
      headerName: "Release Pin",
      flex: 0.3,
      valueGetter: (params) => {
        return params.row.bookingType === "Import" ? params.value : "-";
      },
    },
    {
      field: "loadingTypeExport",
      headerName: "Export Loading Type",
      flex: 0.2,
    },
    {
      field: "loadingTypeImport",
      headerName: "Import Loading Type",
      flex: 0.2,
    },
    {
      field: "cargoDescription",
      headerName: "Cargo",
      flex: 0.3,
    },
    {
      field: "HSCode",
      headerName: "HS Code",
      flex: 0.2,
    },
    {
      field: "cargoValue",
      headerName: "Cargo Value",
      flex: 0.2,
      valueGetter: (params) => {
        return `${params.row.cargoValue?.currency}  ${params.row.cargoValue?.value}`;
      },
    },
    {
      field: "vesselVoyage",
      headerName: "Vessel",
      flex: 0.4,
      valueGetter: (params) => {
        return `${params.row.vesselVoyage?.vesselName} ${params.row.vesselVoyage?.voyageNumber}`;
      },
    },
    {
      field: "dateCreated",
      headerName: "Date Created",
      flex: 0.3,
      valueGetter: (params) => {
        return dayjs(params.row.dateCreated).format("DD/MM/YYYY");
      },
    },
    {
      field: "bookingTeam",
      headerName: "Team",
      flex: 0.2,
    },
    { field: "bookingType", headerName: "Type", flex: 0.2 },
    {
      field: "actions",
      headerName: "Actions",
      renderCell: (cellValues) => {
        return (
          <Box display={"flex"} justifyContent={"space-evenly"}>
            <Tooltip title="View Booking">
              <IconButton
                color="primary"
                onClick={() => {
                  navigate(
                    generatePath(`/bookings/${cellValues.row._id}`, {
                      id: cellValues.row._id,
                    })
                  );
                }}
              >
                <InfoOutlinedIcon />
              </IconButton>
            </Tooltip>
          </Box>
        );
      },
      flex: 0.2,
    },
  ];

  const bookingsColumnVisibilityModel = useSelector(
    (state: any) => state.global.bookingsColumnVisibilityModel
  );

  const bookingsFilters = useSelector(
    (state: any) => state.global.bookingsFilters
  );

  const userEmail = useSelector((state: any) => state.global.userEmail);

  const handleColumnVisibilityChange = (
    newModel: GridColumnVisibilityModel
  ) => {
    dispatch(setBookingsColumnVisibilityModel(newModel));
  };

  const saveUserPreferences = async (userEmail: string) => {
    try {
      const accessToken = await sec.getAccessTokenSilently()();

      const updatePreferenceResponse = await axios.patch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/userPreferences/${userEmail}`,
        {
          bookingsColumnVisibilityModel,
          bookingsFilters,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (
        updatePreferenceResponse.data.message ===
        "User preference does not exist"
      ) {
        await axios.post(
          `${process.env.REACT_APP_BASE_URL}/api/v1/userPreferences`,
          {
            email: userEmail,
            bookingsColumnVisibilityModel,
            bookingsFilters,
          },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
      }
      setSnackbarOpen(true);
    } catch (error) {
      console.error("Error updating user preferences:", error);
    }
  };

  return (
    <Box m="1.5rem 2.5rem">
      <Box>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Box display={"flex"} alignItems={"center"} gap={"1rem"}>
            <Header title="Bookings" />
            <Box>
              <Tooltip title="Create Booking">
                <IconButton
                  sx={{
                    bgcolor: theme.palette.secondary[400],
                    "&:hover": {
                      backgroundColor: theme.palette.secondary[500],
                    },
                  }}
                  onClick={() => {
                    navigate(generatePath(`/bookings/create`));
                  }}
                >
                  <AddOutlined />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
          <Box>
            <Tooltip title="Save Preferences">
              <IconButton onClick={() => saveUserPreferences(userEmail)}>
                <SaveOutlined />
              </IconButton>
            </Tooltip>
            <Tooltip title="Refresh">
              <IconButton onClick={() => refetch()}>
                <RefreshOutlined />
              </IconButton>
            </Tooltip>
            <Tooltip title="Filter">
              <IconButton onClick={() => setDrawerOpen(true)}>
                <FilterAltOutlined />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
      </Box>
      <CustomDataGrid
        data={bookings}
        columns={columns}
        isLoading={isLoading}
        isFetching={isFetching}
        columnVisibilityModel={bookingsColumnVisibilityModel}
        handleColumnVisibilityChange={handleColumnVisibilityChange}
      />
      <BookingsFilterSideBar
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        teams={teams}
      />
      <AlertSnackbar
        open={snackbarOpen}
        handleClose={(_: any, reason: string) =>
          handleSnackbarClose(reason, setSnackbarOpen)
        }
        severity={"success"}
        message={"Preferences saved successfully"}
      />
    </Box>
  );
};

export default Bookings;
