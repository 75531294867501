import React, { Dispatch, SetStateAction, useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import axios from "axios";
import { sec } from "../../auth/accessToken";
import {
  Box,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  TextField,
} from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import GeneralDialogueActions from "components/GeneralDialogueActions";
import { generateMenuItemsFromArray } from "utils/generateMenuItemsFromArray";
import { documentTypes } from "dataAssets/constants";
import { useGetAuditsQuery } from "state/api";
import { Booking, Invoice } from "models/booking.model";
import InvoiceDialogue from "components/InvoiceDialogue";

export interface MultipleFileUploadComponentProps {
  booking: Booking;
  handleClose: Function;
  open: boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  invoiceRefetch?: Function;
}

// todo: cancel should remove uploaded files from the dialog

const MultipleFileUploadComponent: React.FC<
  MultipleFileUploadComponentProps
> = ({ booking, handleClose, open, setIsLoading, invoiceRefetch }) => {
  const [files, setFiles] = useState<File[]>([]);
  const [documentType, setDocumentType] = useState(documentTypes[0]);
  const [createPI, setCreatePI] = useState(false);
  const [currentInvoice, setCurrentInvoice]: [
    Invoice,
    Dispatch<SetStateAction<Invoice>>
  ] = useState({} as Invoice);

  const [openCreateSalesInvoice, setOpenCreateSalesInvoice] = useState(false);

  const { refetch: auditLogsRefetch } = useGetAuditsQuery([
    "Booking",
    booking._id,
  ]);

  const onDrop = useCallback((acceptedFiles: any[]) => {
    setFiles((prevFiles) => [...prevFiles, ...acceptedFiles]);
  }, []);

  const removeFile = (index: number) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: { "application/pdf": [".pdf"] },
    multiple: true,
    minSize: 0,
    maxSize: 10000000,
  });

  const onSubmit = async () => {
    setIsLoading(true);
    const formData = new FormData();
    files.forEach((file) => {
      formData.append("files", file);
    });
    formData.append("bookingNumber", booking.bookingNumber);
    formData.append("documentType", documentType);
    formData.append("bookingId", booking._id);

    try {
      const accessToken = await sec.getAccessTokenSilently()();

      await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/v1/documents/`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      setFiles([]);
      handleClose();
      auditLogsRefetch();
      setDocumentType(documentTypes[0]);

      if (createPI) {
        await axios
          .get(
            `${process.env.REACT_APP_BASE_URL}/api/v1/documents/analyse?documentName=${booking.bookingNumber}/${documentType}/${files[0].name}`,
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            }
          )
          .then((res) => {
            setCurrentInvoice(res.data);
            setOpenCreateSalesInvoice(true);
            setCreatePI(false);
          });
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box>
      <Dialog open={open} onClose={() => handleClose()} fullWidth maxWidth="md">
        <DialogTitle
          sx={{
            fontSize: "1.6rem",
          }}
        >
          Upload Documents
        </DialogTitle>
        <DialogContent>
          <form>
            <Box {...getRootProps()} style={dropzoneStyles}>
              <input {...getInputProps()} />
              <p>
                Drag and drop PDF files here, or click to select them. (PDFs
                have be under 10 MB)
              </p>
            </Box>
            <Box mt={"1rem"} display={"grid"} gridTemplateColumns={"1fr"}>
              <TextField
                select
                label="Document Type"
                value={documentType}
                autoComplete="off"
                onChange={(e) => setDocumentType(e.target.value)}
              >
                {generateMenuItemsFromArray(documentTypes)}
              </TextField>
            </Box>
            {files.length > 0 && (
              <Box mt={"1rem"}>
                <strong>Selected Files:</strong>
                <ul>
                  {files.map((file, index) => (
                    <li key={index}>
                      {file.name}{" "}
                      <IconButton
                        type="button"
                        onClick={() => removeFile(index)}
                      >
                        <CloseOutlinedIcon fontSize="small" />
                      </IconButton>
                    </li>
                  ))}
                </ul>
              </Box>
            )}
            {files.length === 1 && documentType === "Purchase Invoice" && (
              <Box mt={"1rem"}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={createPI}
                      onChange={() => setCreatePI(!createPI)}
                    />
                  }
                  label="Create Purchase Invoice"
                />
              </Box>
            )}
          </form>
        </DialogContent>
        <GeneralDialogueActions
          onClick={onSubmit}
          handleClose={handleClose}
          submitText="Upload"
        />
      </Dialog>
      <InvoiceDialogue
        handleClose={() => {
          setOpenCreateSalesInvoice(false);
          invoiceRefetch && invoiceRefetch();
        }}
        open={openCreateSalesInvoice}
        id={booking._id}
        invoices={booking.purchaseInvoices ?? []}
        booking={booking}
        invoiceType={"purchaseInvoices"}
        currentInvoice={currentInvoice}
      />
    </Box>
  );
};

const dropzoneStyles = {
  border: "0.1rem solid #cccccc",
  borderRadius: "4px",
  padding: "2rem",
  textAlign: "center" as const,
  cursor: "pointer",
};

export default MultipleFileUploadComponent;
