import React from "react";
import { Box, Dialog, DialogContent, DialogTitle } from "@mui/material";
import { PDFViewer } from "@react-pdf/renderer";
import InvoiceConfirmation from "../InvoiceConfirmation";
import { BaseDialogueProps } from "models/index.model";
import { Booking, Invoice } from "models/booking.model";
import { invoiceTypes } from "dataAssets/constants";
import GeneralDialogueActions from "components/GeneralDialogueActions";

export interface InvoiceViewerProps extends BaseDialogueProps {
  invoice: Invoice;
  booking: Booking;
  invoiceType: string;
}

const InvoiceViewer: React.FC<InvoiceViewerProps> = ({
  handleClose,
  open,
  invoice,
  booking,
  invoiceType,
}) => {
  return (
    <Box>
      <Dialog open={open} onClose={() => handleClose()} fullScreen>
        <DialogTitle
          sx={{
            fontSize: "1.6rem",
          }}
        >
          {`${invoiceTypes[invoiceType]}: ${
            invoice.approvedInvoiceNumber ?? invoice.performaInvoiceNumber
          }`}
        </DialogTitle>
        <DialogContent>
          <PDFViewer style={{ width: "100%", height: "100%" }}>
            <InvoiceConfirmation
              invoice={invoice}
              booking={booking}
              invoiceType={invoiceType}
            />
          </PDFViewer>
        </DialogContent>
        <GeneralDialogueActions handleClose={handleClose} noSubmission />
      </Dialog>
    </Box>
  );
};

export default InvoiceViewer;
