import React from "react";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { BaseDialogueProps } from "models/index.model";
import GeneralDialogueActions from "components/GeneralDialogueActions";

export interface ConfirmationDialogProps extends BaseDialogueProps {
  onConfirm: Function;
  title: string;
  content: JSX.Element | string;
}

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
  open,
  handleClose,
  onConfirm,
  title,
  content,
}) => {
  return (
    <Dialog fullWidth maxWidth={"md"} open={open} onClose={() => handleClose()}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{content}</DialogContent>
      <GeneralDialogueActions
        onClick={onConfirm}
        handleClose={handleClose}
        submitText={"Confirm"}
      />
    </Dialog>
  );
};

export default ConfirmationDialog;
