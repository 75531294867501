import React, { useEffect, useState } from "react";
import axios from "axios";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import {
  Box,
  TextField,
  Dialog,
  DialogContent,
  DialogTitle,
  InputAdornment,
  Autocomplete,
} from "@mui/material";
import { sec } from "../../auth/accessToken";
import { transportStatuses } from "../../dataAssets/constants";
import { generateMenuItemsFromArray } from "../../utils/generateMenuItemsFromArray";
import { BaseDialogueProps, VendorType } from "models/index.model";
import AlertSnackbar from "components/AlertSnackbar";
import GeneralDialogueActions from "components/GeneralDialogueActions";
import {
  LoadingData,
  TransportSchedule,
  TransportStatus,
} from "models/booking.model";
import {
  DatePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { useGetVendorsQuery } from "state/api";

export interface LoadingDialogueProps extends BaseDialogueProps {
  loadingToUpdate: LoadingData;
}

const CreateLoadingDialogue: React.FC<LoadingDialogueProps> = ({
  handleClose,
  open,
  loadingToUpdate,
}) => {
  // TODO: we can use `https://github.com/fabien0102/ts-to-zod` to auto create schema
  const loadingUpdateSchema = z
    .object({
      status: z.nativeEnum(TransportStatus),
      site: z.string(),
      hauler: z.string(),
      remarks: z.string().optional(),
      reference: z.string().optional(),
      containerNumber: z.string().optional(),
      sealNumber: z.string().optional(),
      weight: z.string(),
      date: z.string(),
      time: z.string(),
    })
    // Perform conditional validation to ensure reference is selected is status is completed.
    .superRefine(
      (
        { status, reference, containerNumber, weight, sealNumber },
        refinementContext
      ) => {
        if (status !== TransportStatus.Completed) {
          return;
        }
        const check: Map<string, string | undefined> = new Map([
          ["reference", reference],
          ["containerNumber", containerNumber],
          ["sealNumber", sealNumber],
          ["weight", weight],
        ]);
        check.forEach((val, key) => {
          if (typeof val === "undefined" || val === "") {
            refinementContext.addIssue({
              code: z.ZodIssueCode.custom,
              message: `${key.charAt(0).toUpperCase()}${key.slice(
                1
              )} is required for marking status as completed`,
              path: [key],
            });
          }
        });
      }
    );

  type LoadingSchemaType = z.infer<typeof loadingUpdateSchema>;

  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isDirty },
    reset,
  } = useForm<LoadingSchemaType>({
    resolver: zodResolver(loadingUpdateSchema),
  });

  useEffect(() => {
    reset(loadingToUpdate);
  }, [loadingToUpdate, reset]);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const handleSnackbarClose = (_: any, reason: string) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const { data: vendors, isLoading: vendorsIsLoading } = useGetVendorsQuery();

  const onSubmit = async (data: LoadingSchemaType) => {
    try {
      const transportData: TransportSchedule = {
        ...data,
        _id: loadingToUpdate._id,
        date: dayjs(data.date).format(),
        time: dayjs(data.time).format(),
      };
      const accessToken = await sec.getAccessTokenSilently()();
      await axios.patch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/bookings/${loadingToUpdate.bookingId}?type=transportSchedule`,
        transportData,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      setSnackbarOpen(true);
      handleClose();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Box>
      <Dialog open={open} onClose={() => handleClose()} fullWidth maxWidth="xl">
        <DialogTitle
          sx={{
            fontSize: "1.6rem",
          }}
        >
          Update Loading Schedule
        </DialogTitle>
        <DialogContent dividers>
          <form>
            <Box
              display={"grid"}
              gridTemplateColumns={"1fr 1fr 1fr 1fr"}
              gap={"1rem"}
            >
              <TextField
                label={"Site *"}
                autoComplete="off"
                variant="outlined"
                {...register("site", {
                  required: true,
                })}
                error={!!errors?.site}
                helperText={errors?.site?.message}
              />
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Controller
                  name="date"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <Box>
                      <DatePicker
                        slotProps={{ textField: { fullWidth: true } }}
                        format="DD/MM/YYYY"
                        label={"Date"}
                        value={value ? dayjs(value) : null}
                        onChange={(date) => onChange(dayjs(date).format())}
                        // TODO: min and max date is not handled
                      />
                    </Box>
                  )}
                />
                <Controller
                  name="time"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <TimePicker
                      label="Time"
                      value={value ? dayjs(value) : null}
                      onChange={(time) => onChange(dayjs(time).format())}
                    />
                  )}
                />
              </LocalizationProvider>
              <TextField
                label={"Reference"}
                autoComplete="off"
                variant="outlined"
                {...register("reference")}
                error={!!errors.reference}
                helperText={errors.reference?.message}
              />
              <TextField
                label={"Container Number"}
                autoComplete="off"
                variant="outlined"
                {...register("containerNumber")}
                error={!!errors.containerNumber}
                helperText={errors.containerNumber?.message}
              />
              <TextField
                label={"Seal Number"}
                autoComplete="off"
                variant="outlined"
                {...register("sealNumber")}
                error={!!errors.sealNumber}
                helperText={errors.sealNumber?.message}
              />
              <TextField
                label={"Weight"}
                type="number"
                autoComplete="off"
                variant="outlined"
                {...register("weight")}
                error={!!errors.weight}
                helperText={errors.weight?.message}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">KG</InputAdornment>
                  ),
                }}
              />
              <Box>
                {vendors || vendorsIsLoading ? (
                  <Controller
                    name="hauler"
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { value, onChange } }) => (
                      <Autocomplete
                        disablePortal
                        value={value ? { label: value } : null}
                        onChange={(_, value) => {
                          onChange(value?.label || "");
                        }}
                        options={
                          vendors
                            ?.filter(
                              (vendor) =>
                                vendor.vendorType === VendorType.Haulier
                            )
                            .map((item) => ({
                              label: item.name,
                            })) || []
                        }
                        isOptionEqualToValue={(option, value) =>
                          option.label === value.label
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label="Hauler *"
                            error={!!errors?.hauler}
                            helperText={errors?.hauler?.message}
                          />
                        )}
                      />
                    )}
                  />
                ) : null}
              </Box>
              <TextField
                label={"Remarks"}
                autoComplete="off"
                variant="outlined"
                {...register("remarks")}
              />
              <Controller
                name={"status"}
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    select
                    label="Status"
                    error={!!errors.status}
                    helperText={errors.status?.message}
                  >
                    {generateMenuItemsFromArray(transportStatuses)}
                  </TextField>
                )}
              />
            </Box>
          </form>
        </DialogContent>
        <GeneralDialogueActions
          onClick={handleSubmit(onSubmit)}
          handleClose={handleClose}
          submitText="Update"
          noSubmission={!isDirty}
        />
      </Dialog>
      <AlertSnackbar
        open={snackbarOpen}
        handleClose={handleSnackbarClose}
        severity="success"
        message="Loading updated"
      />
    </Box>
  );
};

export default CreateLoadingDialogue;
