import React, { Fragment, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Box,
  useTheme,
  IconButton,
  Typography,
  Divider,
  Tooltip,
  CircularProgress,
} from "@mui/material";
import { sec } from "../../auth/accessToken";
import QuotationDialogue from "../QuotationDialogue";
import AssignmentTurnedInOutlinedIcon from "@mui/icons-material/AssignmentTurnedInOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DownloadOutlined from "@mui/icons-material/DownloadOutlined";
import { Booking } from "models/booking.model";
import { useGetAuditsQuery, useGetExpectedProfitQuery } from "state/api";
import ProfitDisplay from "components/ProfitDisplay";
import { PDFDownloadLink } from "@react-pdf/renderer";
import BookingConfirmation from "components/BookingConfirmation";
import UpdateTermsAndConditions from "components/UpdateTermsAndConditions";
import { FormatListNumberedOutlined } from "@mui/icons-material";

export interface QuotationTableProps {
  booking: Booking;
  refetch: Function;
}

const QuotationTable: React.FC<QuotationTableProps> = ({
  booking,
  refetch,
}) => {
  const theme = useTheme();

  const userPermissions = useSelector((state: any) => state.global.permissions);

  const { refetch: auditLogsRefetch } = useGetAuditsQuery([
    "Booking",
    booking._id,
  ]);
  const { data: expectedProfit } = useGetExpectedProfitQuery(booking._id);

  const [openQuotation, setOpenQuotation] = useState(false);
  const handleClickOpenQuotation = () => {
    setOpenQuotation(true);
  };
  const handleCloseQuotation = () => {
    setOpenQuotation(false);
    refetch();
  };

  const [termsAndConditionsOpen, setTermsAndConditionsOpen] = useState(false);
  const toggleTermsAndConditionsDialogue = (open: boolean) => {
    setTermsAndConditionsOpen(open);
    if (open) {
      refetch();
    }
  };

  const updateApproval = async () => {
    try {
      const accessToken = await sec.getAccessTokenSilently()();

      await axios.patch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/bookings/${booking._id}`,
        { quotationApproval: true },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      refetch();
      auditLogsRefetch();
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Box>
      <Box>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Typography
            sx={{
              letterSpacing: "0.8px",
            }}
          >
            Quotation Details
          </Typography>
          {booking.quotationApproval === false ? (
            <Box>
              <Tooltip title="Edit Quotation">
                <IconButton onClick={handleClickOpenQuotation}>
                  <EditOutlinedIcon />
                </IconButton>
              </Tooltip>
              {userPermissions.includes("update:approval-quotation") && (
                <Tooltip title="Approve Quotation">
                  <IconButton onClick={updateApproval}>
                    <AssignmentTurnedInOutlinedIcon />
                  </IconButton>
                </Tooltip>
              )}
            </Box>
          ) : (
            <Box>
              <PDFDownloadLink
                document={
                  <BookingConfirmation
                    booking={booking}
                    showQuotation={true}
                    onlyShowQuotation={true}
                  />
                }
                fileName={`${booking.bookingNumber}_Quotation.pdf`}
              >
                {({ loading }) =>
                  loading ? (
                    <CircularProgress size={"1rem"} />
                  ) : (
                    <Tooltip title="Download Quotation">
                      <IconButton>
                        <DownloadOutlined />
                      </IconButton>
                    </Tooltip>
                  )
                }
              </PDFDownloadLink>
              <Tooltip title="Edit T&Cs">
                <IconButton
                  onClick={() => toggleTermsAndConditionsDialogue(true)}
                >
                  <FormatListNumberedOutlined />
                </IconButton>
              </Tooltip>
            </Box>
          )}
        </Box>
      </Box>
      <Table
        size="small"
        sx={{
          "& .MuiTableCell-root": {
            borderBottom: "none",
            color: theme.palette.secondary[100],
          },
          "& .MuiTableHead-root .MuiTableCell-root": {
            fontWeight: "600",
          },
        }}
      >
        <TableHead>
          <TableRow>
            <TableCell>Charge Name</TableCell>
            <TableCell>Currency</TableCell>
            <TableCell>Rate</TableCell>
            <TableCell>Base</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {["Buy Rates", "Sell Rates"].map((ratesType, ratesIndex) => (
            <Fragment key={ratesIndex}>
              <TableRow>
                <TableCell colSpan={4}>
                  <Divider>
                    <Typography variant="caption">{ratesType}</Typography>
                  </Divider>
                </TableCell>
              </TableRow>

              {(ratesType === "Buy Rates"
                ? booking.buyRates
                : booking.sellRates
              ).map((charge, index) => (
                <TableRow key={index}>
                  <TableCell>{charge.chargeName}</TableCell>
                  <TableCell>{charge.currency}</TableCell>
                  <TableCell>{charge.rate}</TableCell>
                  <TableCell>{charge.base}</TableCell>
                </TableRow>
              ))}
            </Fragment>
          ))}
        </TableBody>
      </Table>
      <ProfitDisplay
        expectedProfit={expectedProfit}
        bookingCurrency={booking.bookingCurrency}
        numberOfContainers={booking.numberOfContainers}
      />
      <QuotationDialogue
        open={openQuotation}
        handleClose={handleCloseQuotation}
        id={booking._id}
        buyRates={booking.buyRates}
        sellRates={booking.sellRates}
      />
      <UpdateTermsAndConditions
        handleClose={() => toggleTermsAndConditionsDialogue(false)}
        open={termsAndConditionsOpen}
        id={booking._id}
        termsAndConditions={booking.quotationTermsAndConditions}
        type={"quotation"}
      />
    </Box>
  );
};

export default QuotationTable;
