import React, { useEffect, useState } from "react";
import axios from "axios";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import {
  Box,
  TextField,
  Dialog,
  DialogContent,
  DialogTitle,
  Chip,
  Autocomplete,
} from "@mui/material";
import { sec } from "../../auth/accessToken";
import { useGetOauthQuery } from "state/api";
import { BaseDialogueProps, Oauth } from "models/index.model";
import AlertSnackbar from "components/AlertSnackbar";
import GeneralDialogueActions from "components/GeneralDialogueActions";

export interface OauthDialogueProps extends BaseDialogueProps {
  oauthToUpdate?: Oauth;
}

const CreateOauthDialogue: React.FC<OauthDialogueProps> = ({
  handleClose,
  open,
  oauthToUpdate,
}) => {
  const isEditing = !!oauthToUpdate?._id;

  const { data: oauthList } = useGetOauthQuery();

  const oauthCreationSchema = z.object({
    provider: z.string().min(1, { message: "provider is required" }).refine(
      (name) => {
        return !oauthList?.find(
          (site) => site.provider === name && site._id !== oauthToUpdate?._id
        );
      },
      { message: "A oauth with that provider already exists" }
    ),
    clientId: z.string().min(1, { message: "clientId is required" }),
    clientSecret: z.string().min(1, { message: "clientSecret is required" }),
    scopes: z.string().array().optional(),
    authUrl: z.string().optional(),
    tokenUrl: z.string().url().min(1, { message: "tokenUrl is required" }),
    redirectUrl: z.string().optional(),
  }).superRefine(({ redirectUrl, authUrl, scopes }, refinementContext) => {
    if (typeof authUrl === "undefined" || authUrl === "") {
      return;
    }
    if (typeof redirectUrl === "undefined" || redirectUrl === "") {
      refinementContext.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Redirect URL is required while providing authUrl",
        path: ["redirectUrl"],
      });
    }
    if (typeof scopes === "undefined" || scopes.length <= 0) {
      refinementContext.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Scopes is required while providing authUrl",
        path: ["scopes"],
      });
    }
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
  } = useForm<Oauth>({
    resolver: zodResolver(oauthCreationSchema),
  });

  useEffect(() => {
    reset(oauthToUpdate);
  }, [oauthToUpdate, reset]);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const handleSnackbarClose = (_: any, reason: string) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbarOpen(false);
  };

  const onSubmit = async (data: Oauth) => {
    try {
      const accessToken = await sec.getAccessTokenSilently()();
      let apiUrl = `${process.env.REACT_APP_BASE_URL}/api/v1/oauth/`;

      if (isEditing) {
        apiUrl += `${oauthToUpdate?._id}`;
        await axios.patch(apiUrl, data, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
      } else {
        await axios.post(apiUrl, data, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
      }
      setSnackbarOpen(true);
      handleClose();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Box>
      <Dialog open={open} onClose={() => handleClose()} fullWidth maxWidth="sm">
        <DialogTitle
          sx={{
            fontSize: "1.6rem",
          }}
        >
          {isEditing ? "Edit Oauth" : "Create Oauth"}
        </DialogTitle>
        <DialogContent>
          <form>
            <Box display={"grid"} gridTemplateColumns={"1fr"} gap={"0.5rem"}>
              <TextField
                label="Provider *"
                autoComplete="off"
                variant="filled"
                {...register("provider")}
                error={!!errors.provider}
                helperText={errors.provider?.message}
              />
              <TextField
                label="Client ID *"
                autoComplete="off"
                variant="filled"
                {...register("clientId")}
                error={!!errors.clientId}
                helperText={errors.clientId?.message}
              />
              <TextField
                label="Client Secret *"
                autoComplete="off"
                variant="filled"
                {...register("clientSecret")}
                error={!!errors.clientSecret}
                helperText={errors.clientSecret?.message}
              />
              <TextField
                label="Redirect URL"
                autoComplete="off"
                variant="filled"
                {...register("redirectUrl")}
                error={!!errors.redirectUrl}
                helperText={errors.redirectUrl?.message}
              />
              <Controller
                control={control}
                name="scopes"
                render={({ field: { value, onChange } }) => (
                  <Autocomplete
                    multiple
                    id="scopes-filled"
                    options={[]}
                    freeSolo
                    value={value}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          variant="outlined"
                          label={option}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    onChange={(event, values) => {
                      onChange(values);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Scopes"
                        placeholder="Add scope with ↵"
                        helperText={errors.scopes?.message}
                        error={!!errors.scopes}
                      />
                    )}
                  />
                )}
              />
              <TextField
                label="Auth URL"
                autoComplete="off"
                variant="filled"
                {...register("authUrl")}
                error={!!errors.authUrl}
                helperText={errors.authUrl?.message}
              />
              <TextField
                label="Token URL *"
                autoComplete="off"
                variant="filled"
                {...register("tokenUrl")}
                error={!!errors.tokenUrl}
                helperText={errors.tokenUrl?.message}
              />
            </Box>
          </form>
        </DialogContent>
        <GeneralDialogueActions
          onClick={handleSubmit(onSubmit)}
          handleClose={handleClose}
          submitText={isEditing ? "Update" : "Create"}
        />
      </Dialog>
      <AlertSnackbar
        open={snackbarOpen}
        handleClose={handleSnackbarClose}
        severity={"success"}
        message={isEditing ? "Oauth Updated" : "Oauth Created"}
      />
    </Box>
  );
};

export default CreateOauthDialogue;
