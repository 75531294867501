import React, { useState } from "react";
import {
  Box,
  SpeedDial,
  SpeedDialAction,
  SpeedDialIcon,
  useTheme,
} from "@mui/material";
import {
  AddOutlined,
  DeleteOutlined,
  FileCopyOutlined,
  CheckCircleOutlined,
  BlockOutlined,
  CallSplitOutlined,
  MergeOutlined,
  LockOpenOutlined,
} from "@mui/icons-material";
import { generatePath, useNavigate } from "react-router-dom";
import { useUserPermissions } from "utils/utils";
import {
  duplicateBooking,
  deleteBooking,
  completeBooking,
  cancelBooking,
  reopenBooking,
} from "utils/serverFunctions";
import { BookingStatus, StepDetails } from "models/booking.model";
import ConfirmDialogue from "components/ConfirmDialogue";
import { useGetAuditsQuery, useGetBookingProfitQuery } from "state/api";

export interface BookingSpeedDialProps {
  bookingId: string;
  bookingNumber: string;
  bookingStatus: BookingStatus;
  stepsCompleted: StepDetails[];
  refetch: Function;
  showSplitBooking: boolean;
  openSplitBookingDialogue: Function;
  openMergeBookingDialogue: Function;
}

const BookingSpeedDial: React.FC<BookingSpeedDialProps> = ({
  bookingId,
  bookingNumber,
  bookingStatus,
  stepsCompleted,
  refetch,
  showSplitBooking,
  openSplitBookingDialogue,
  openMergeBookingDialogue,
}) => {
  const theme = useTheme();
  const SpeedDialActionsStyles = {
    "&.MuiSpeedDialAction-fab": {
      backgroundColor: theme.palette.secondary[500],
      "&:hover": {
        backgroundColor: theme.palette.secondary[600],
      },
    },
  };
  const SpeedDialStyles = {
    position: "fixed",
    bottom: "1rem",
    right: "1rem",
  };

  const userPermissions = useUserPermissions();
  const navigate = useNavigate();

  const { data: bookingIncome } = useGetBookingProfitQuery(bookingId);
  const { refetch: auditLogsRefetch } = useGetAuditsQuery([
    "Booking",
    bookingId,
  ]);

  const [openSpeedDial, setOpenSpeedDial] = React.useState(false);

  const handleCreateBooking = () => {
    navigate(`/bookings/create`);
  };
  const handleDuplicateBooking = async () => {
    const newBookingId = await duplicateBooking(bookingId);
    navigate(
      generatePath(`/bookings/${newBookingId}`, {
        id: newBookingId,
      })
    );
    window.location.reload();
  };
  const handleDeleteBooking = async () => {
    await deleteBooking(bookingId, bookingNumber);
    navigate(`/bookings`);
  };
  const handleBookingCompletion = async () => {
    await completeBooking(bookingId, bookingIncome);
    refetch();
    auditLogsRefetch();
  };
  const handleBookingCancellation = async () => {
    await cancelBooking(bookingId);
    refetch();
    auditLogsRefetch();
  };
  const handleBookingReopening = async () => {
    await reopenBooking(bookingId);
    refetch();
    auditLogsRefetch();
  };

  const [actionToConfirm, setActionToConfirm] = useState<(() => void) | null>(
    null
  );
  const [dialogTitle, setDialogTitle] = useState("");
  const [dialogContent, setDialogContent] = useState("");

  const handleActionConfirmation = async () => {
    if (actionToConfirm) {
      actionToConfirm();
      setActionToConfirm(null);
    }
  };

  const createActionHandler = (
    actionFn: () => void,
    title: string,
    content: string
  ) => {
    setActionToConfirm(() => actionFn);
    setDialogTitle(title);
    setDialogContent(content);
  };

  const isStepsPending = stepsCompleted.length < 6;

  return (
    <Box>
      <SpeedDial
        ariaLabel="Booking SpeedDial"
        sx={SpeedDialStyles}
        direction="up"
        icon={<SpeedDialIcon />}
        onClose={() => setOpenSpeedDial(false)}
        onOpen={() => setOpenSpeedDial(true)}
        open={openSpeedDial}
        FabProps={{
          sx: {
            height: "4rem",
            width: "4rem",
            bgcolor: theme.palette.secondary[500],
            "&:hover": {
              bgcolor: theme.palette.secondary[600],
            },
          },
        }}
      >
        <SpeedDialAction
          sx={SpeedDialActionsStyles}
          icon={<AddOutlined />}
          tooltipTitle={"Create Booking"}
          onClick={handleCreateBooking}
        />
        <SpeedDialAction
          sx={SpeedDialActionsStyles}
          icon={<FileCopyOutlined />}
          tooltipTitle={"Duplicate Booking"}
          onClick={() =>
            createActionHandler(
              handleDuplicateBooking,
              "Duplicate Booking",
              "Are you sure you want to duplicate this booking?"
            )
          }
        />
        {isStepsPending && showSplitBooking && (
          <SpeedDialAction
            sx={SpeedDialActionsStyles}
            icon={<CallSplitOutlined />}
            tooltipTitle={"Split Booking"}
            onClick={() => openSplitBookingDialogue()}
          />
        )}
        {isStepsPending && (
          <SpeedDialAction
            sx={SpeedDialActionsStyles}
            icon={<MergeOutlined />}
            tooltipTitle={"Merge Bookings"}
            onClick={() => openMergeBookingDialogue()}
          />
        )}
        {userPermissions.includes("delete:booking") && (
          <SpeedDialAction
            sx={SpeedDialActionsStyles}
            icon={<DeleteOutlined />}
            tooltipTitle={"Delete Booking"}
            onClick={() =>
              createActionHandler(
                handleDeleteBooking,
                "Delete Booking",
                "Are you sure you want to delete this booking?"
              )
            }
          />
        )}
        {bookingStatus === BookingStatus.Ongoing && (
          <SpeedDialAction
            sx={SpeedDialActionsStyles}
            icon={<BlockOutlined />}
            tooltipTitle={"Cancel Booking"}
            onClick={() =>
              createActionHandler(
                handleBookingCancellation,
                "Cancel Booking",
                "Are you sure you want to cancel this booking?"
              )
            }
          />
        )}
        {bookingStatus === BookingStatus.Ongoing &&
          userPermissions.includes("update:booking-completion") && (
            <SpeedDialAction
              sx={SpeedDialActionsStyles}
              icon={<CheckCircleOutlined />}
              tooltipTitle={"Complete Booking"}
              onClick={() =>
                createActionHandler(
                  handleBookingCompletion,
                  "Complete Booking",
                  "Are you sure you want to complete this booking?"
                )
              }
              FabProps={{ disabled: isStepsPending }}
            />
          )}
        {bookingStatus === BookingStatus.Completed &&
          userPermissions.includes("update:booking-completion") && (
            <SpeedDialAction
              sx={SpeedDialActionsStyles}
              icon={<LockOpenOutlined />}
              tooltipTitle={"Reopen Booking"}
              onClick={() =>
                createActionHandler(
                  handleBookingReopening,
                  "Reopen Booking",
                  "Are you sure you want to reopen this booking?"
                )
              }
            />
          )}
      </SpeedDial>
      <ConfirmDialogue
        open={!!actionToConfirm}
        handleClose={() => setActionToConfirm(null)}
        onConfirm={handleActionConfirmation}
        title={dialogTitle}
        content={dialogContent}
      />
    </Box>
  );
};

export default BookingSpeedDial;
