import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, TextField, Drawer, Autocomplete, Button } from "@mui/material";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { clearBookingsFilters, setBookingsFilters } from "state";
import dayjs from "dayjs";
import { generateMenuItemsFromArray } from "utils/generateMenuItemsFromArray";
import {
  blStatuses,
  bookingStatuses,
  containerTypes,
} from "dataAssets/constants";
import { TeamType } from "models/booking.model";
import {
  useGetCustomersQuery,
  useGetPortsQuery,
  useGetVendorsQuery,
} from "state/api";
import {
  getValueAndLabelFromCustomer,
  getValueAndLabelFromPort,
  getValueAndLabelFromVendor,
} from "utils/utils";
import { Customer, Port, Vendor, VendorType } from "models/index.model";
import { ClearOutlined } from "@mui/icons-material";

interface SidebarProps {
  open: boolean;
  onClose: () => void;
  teams: TeamType[] | undefined;
}

const Sidebar: React.FC<SidebarProps> = ({ open, onClose, teams }) => {
  const dispatch = useDispatch();

  const {
    startDate,
    endDate,
    bookingStatus,
    bookingTeam,
    consignor,
    consignee,
    carrier,
    portOfLoading,
    portOfDestination,
    containerType,
    salesInvoiceCreated,
    purchaseInvoiceCreated,
    blStatus,
    outstandingAdditionalCharges,
  } = useSelector((state: any) => state.global.bookingsFilters);

  const { data: customers } = useGetCustomersQuery();
  const { data: vendors } = useGetVendorsQuery();
  const { data: ports } = useGetPortsQuery();

  const handleStartDateChange = (newDate: dayjs.Dayjs | null) => {
    if (newDate && newDate.isValid()) {
      dispatch(setBookingsFilters({ startDate: newDate.toISOString() }));
    }
  };
  const handleEndDateChange = (newDate: dayjs.Dayjs | null) => {
    if (newDate && newDate.isValid()) {
      dispatch(setBookingsFilters({ endDate: newDate.toISOString() }));
    }
  };
  const handleBookingStatusChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    dispatch(setBookingsFilters({ bookingStatus: e.target.value }));
  };
  const handleBookingTeamChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setBookingsFilters({ bookingTeam: e.target.value }));
  };

  return (
    <Drawer anchor="right" open={open} onClose={onClose}>
      <Box
        display="flex"
        flexDirection="column"
        gap="1rem"
        p="1rem"
        width="300px"
      >
        <TextField
          select
          label="Status"
          value={bookingStatus}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleBookingStatusChange(e)
          }
        >
          {generateMenuItemsFromArray(bookingStatuses)}
        </TextField>
        <TextField
          select
          label="Team"
          value={bookingTeam}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleBookingTeamChange(e)
          }
        >
          {generateMenuItemsFromArray(teams ?? [])}
        </TextField>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="Creation Start"
            format="DD/MM/YYYY"
            value={dayjs(startDate)}
            onChange={handleStartDateChange}
          />
          <DatePicker
            label="Creation End"
            format="DD/MM/YYYY"
            value={dayjs(endDate)}
            onChange={handleEndDateChange}
          />
        </LocalizationProvider>
        <Autocomplete
          value={consignor ? getValueAndLabelFromCustomer(consignor) : null}
          options={(customers ?? []).map((customer: Customer) =>
            getValueAndLabelFromCustomer(customer)
          )}
          isOptionEqualToValue={(option, value) => option.label === value.label}
          onChange={(_, value) => {
            const newCustomer = customers?.find(
              (customer: Customer) => customer._id === value?.value
            );
            if (newCustomer) {
              dispatch(setBookingsFilters({ consignor: newCustomer }));
            } else {
              dispatch(setBookingsFilters({ consignor: undefined }));
            }
          }}
          renderInput={(params) => <TextField {...params} label="Consignor" />}
        />
        <Autocomplete
          value={consignee ? getValueAndLabelFromCustomer(consignee) : null}
          options={(customers ?? []).map((customer: Customer) =>
            getValueAndLabelFromCustomer(customer)
          )}
          isOptionEqualToValue={(option, value) => option.label === value.label}
          onChange={(_, value) => {
            const newCustomer = customers?.find(
              (customer: Customer) => customer._id === value?.value
            );
            if (newCustomer) {
              dispatch(setBookingsFilters({ consignee: newCustomer }));
            } else {
              dispatch(setBookingsFilters({ consignee: undefined }));
            }
          }}
          renderInput={(params) => <TextField {...params} label="Consignee" />}
        />
        <Autocomplete
          value={carrier ? getValueAndLabelFromVendor(carrier) : null}
          options={(vendors ?? [])
            .filter(
              (vendor: Vendor) => vendor.vendorType === VendorType.Carrier
            )
            .map((vendor: Vendor) => getValueAndLabelFromVendor(vendor))}
          isOptionEqualToValue={(option, value) => option.label === value.label}
          onChange={(_, value) => {
            const newVendor = vendors?.find(
              (vendor: Vendor) => vendor._id === value?.value
            );
            if (newVendor) {
              dispatch(setBookingsFilters({ carrier: newVendor }));
            } else {
              dispatch(setBookingsFilters({ carrier: undefined }));
            }
          }}
          renderInput={(params) => <TextField {...params} label="Carrier" />}
        />
        <Autocomplete
          value={portOfLoading ? getValueAndLabelFromPort(portOfLoading) : null}
          options={(ports ?? []).map((port: Port) =>
            getValueAndLabelFromPort(port)
          )}
          isOptionEqualToValue={(option, value) => option.label === value.label}
          onChange={(_, value) => {
            const newPort = ports?.find(
              (port: Port) => port._id === value?.value
            );
            if (newPort) {
              dispatch(setBookingsFilters({ portOfLoading: newPort }));
            } else {
              dispatch(setBookingsFilters({ portOfLoading: undefined }));
            }
          }}
          renderInput={(params) => <TextField {...params} label="POL " />}
        />
        <Autocomplete
          value={
            portOfDestination
              ? getValueAndLabelFromPort(portOfDestination)
              : null
          }
          options={(ports ?? []).map((port: Port) =>
            getValueAndLabelFromPort(port)
          )}
          isOptionEqualToValue={(option, value) => option.label === value.label}
          onChange={(_, value) => {
            const newPort = ports?.find(
              (port: Port) => port._id === value?.value
            );
            if (newPort) {
              dispatch(setBookingsFilters({ portOfDestination: newPort }));
            } else {
              dispatch(setBookingsFilters({ portOfDestination: undefined }));
            }
          }}
          renderInput={(params) => <TextField {...params} label="POD " />}
        />
        <Autocomplete
          value={containerType ? containerType : null}
          options={containerTypes}
          isOptionEqualToValue={(option, value) => option === value}
          onChange={(_, value) => {
            if (value) {
              dispatch(setBookingsFilters({ containerType: value }));
            } else {
              dispatch(setBookingsFilters({ containerType: undefined }));
            }
          }}
          renderInput={(params) => (
            <TextField {...params} label="Container Type" />
          )}
        />
        <Autocomplete
          value={blStatus ? blStatus : null}
          options={blStatuses}
          isOptionEqualToValue={(option, value) => option === value}
          onChange={(_, value) => {
            if (value) {
              dispatch(setBookingsFilters({ blStatus: value }));
            } else {
              dispatch(setBookingsFilters({ blStatus: undefined }));
            }
          }}
          renderInput={(params) => <TextField {...params} label="BL Status" />}
        />
        <Autocomplete
          value={salesInvoiceCreated ? salesInvoiceCreated : null}
          options={["Yes", "No"]}
          isOptionEqualToValue={(option, value) => option === value}
          onChange={(_, value) => {
            if (value) {
              dispatch(setBookingsFilters({ salesInvoiceCreated: value }));
            } else {
              dispatch(setBookingsFilters({ salesInvoiceCreated: undefined }));
            }
          }}
          renderInput={(params) => (
            <TextField {...params} label="Sales Invoice Created" />
          )}
        />
        <Autocomplete
          value={purchaseInvoiceCreated ? purchaseInvoiceCreated : null}
          options={["Yes", "No"]}
          isOptionEqualToValue={(option, value) => option === value}
          onChange={(_, value) => {
            if (value) {
              dispatch(setBookingsFilters({ purchaseInvoiceCreated: value }));
            } else {
              dispatch(
                setBookingsFilters({ purchaseInvoiceCreated: undefined })
              );
            }
          }}
          renderInput={(params) => (
            <TextField {...params} label="Purchase Invoice Created" />
          )}
        />
        <Autocomplete
          value={
            outstandingAdditionalCharges ? outstandingAdditionalCharges : null
          }
          options={["Yes", "No"]}
          isOptionEqualToValue={(option, value) => option === value}
          onChange={(_, value) => {
            if (value) {
              dispatch(
                setBookingsFilters({ outstandingAdditionalCharges: value })
              );
            } else {
              dispatch(
                setBookingsFilters({ outstandingAdditionalCharges: undefined })
              );
            }
          }}
          renderInput={(params) => (
            <TextField {...params} label="Outstanding Additional Charges" />
          )}
        />
        <Button
          color="error"
          startIcon={<ClearOutlined />}
          onClick={() => {
            dispatch(clearBookingsFilters());
          }}
        >
          Clear Filters
        </Button>
      </Box>
    </Drawer>
  );
};

export default Sidebar;
