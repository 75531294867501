import React, { useReducer, useState } from "react";
import { useGetOauthQuery } from "../../state/api";
import {
  AddOutlined,
  DeleteOutlined,
  EditOutlined,
  PlayCircleOutline,
} from "@mui/icons-material";
import { GridCellParams, GridColDef } from "@mui/x-data-grid";
import Header from "../../components/Header";
import CreateOauthDialogue from "../../components/CreateOauthDialogue";
import { Box, Button, IconButton, Tooltip, useTheme } from "@mui/material";
import { Oauth } from "models/index.model";
import { initialState, reducer } from "../../utils/dialogState";
import { useUserPermissions } from "utils/utils";
import { sec } from "auth/accessToken";
import axios from "axios";
import CustomDataGrid from "components/CustomDataGrid";
import AlertSnackbar from "components/AlertSnackbar";
import { useNavigate } from "react-router-dom";

const Oauths = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const userPermissions = useUserPermissions();
  const hasReadMasterDataPermission =
    userPermissions.includes("read:access_token");
  const hasCreateMasterDataPermission = userPermissions.includes(
    "create:access_token"
  );
  const hasUpdateMasterDataPermission = userPermissions.includes(
    "update:access_token"
  );
  const hasDeleteMasterDataPermission = userPermissions.includes(
    "delete:access_token"
  );

  if (
    Array.isArray(userPermissions) &&
    userPermissions.length > 0 &&
    !hasReadMasterDataPermission
  ) {
    // TODO: change to permission denied page in the future
    navigate("/");
  }

  const [{ open, entityToUpdate }, dispatch] = useReducer(
    reducer<Oauth>,
    initialState<Oauth>()
  );

  const handleClickOpenCreate = () => {
    dispatch({ type: "OPEN_DIALOG", payload: {} as Oauth });
  };

  const handleClickOpenEdit = (oauth: Oauth) => {
    dispatch({ type: "OPEN_DIALOG", payload: oauth });
  };

  const handleClose = () => {
    dispatch({ type: "CLOSE_DIALOG" });
    refetch();
  };

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const handleSnackbarClose = (_: any, reason: string) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const generateToken = async (oauth: Oauth, code?: string) => {
    const { tokens, scopes, ...payload } = oauth;
    const details: Record<string, string> = {
      ...payload,
    };
    if (typeof code !== "undefined") {
      details.code = code;
    }
    const apiUrl = `${process.env.REACT_APP_BASE_URL}/api/v1/token/`;
    const accessToken = await sec.getAccessTokenSilently()();
    await axios.post(apiUrl, new URLSearchParams(details), {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    setSnackbarOpen(true);
  };

  const generateCode = async (oauth: Oauth) => {
    if (typeof oauth.authUrl === "undefined" || oauth.authUrl === "") {
      return generateToken(oauth);
    }
    const authUrl = new URL(oauth.authUrl);
    const params = new URLSearchParams([
      ...Object.entries({
        response_type: "code",
        client_id: oauth.clientId,
        redirect_uri: oauth.redirectUrl,
        scope: oauth.scopes.join("+"),
      }),
    ]);
    authUrl.search = params.toString();
    const win = window.open(authUrl, "_blank");
    if (win === null) {
      return;
    }

    const eventListner: (event: any) => any = (event) => {
      if (typeof (event?.source as Window)?.location === "undefined") {
        return;
      }
      if ((event?.source as Window)?.location !== win.location) {
        console.error(
          "received event from :: ",
          (event?.source as Window)?.location
        );
        return;
      }
      const { code } = event.data;
      if (typeof code !== "string") {
        console.error("received event doesnt have code :: ", event.data);
        return;
      }
      generateToken(oauth, code);
      win.close();
      window.removeEventListener("message", eventListner);
    };
    try {
      window.removeEventListener("message", eventListner);
    } catch (e) {
      console.log(e);
    }
    window.addEventListener("message", eventListner, { once: true });

    win.focus();
  };

  const { data: oauthList, isLoading, refetch } = useGetOauthQuery();

  const deleteOauth = async (oauthId: string) => {
    try {
      const accessToken = await sec.getAccessTokenSilently()();
      await axios.delete(
        `${process.env.REACT_APP_BASE_URL}/api/v1/oauth/${oauthId}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      refetch();
    } catch (error) {
      console.error(error);
    }
  };

  const columns: GridColDef[] = [
    {
      field: "provider",
      headerName: "Provider",
      flex: 1,
    },
    {
      field: "clientId",
      headerName: "Client ID",
      flex: 1,
    },
    {
      field: "clientSecret",
      headerName: "Client Secret",
      flex: 1,
    },
    {
      field: "Actions",
      headerName: "Actions",
      flex: 0.5,
      headerAlign: "center",
      align: "center",
      renderCell: (cellValues: GridCellParams) => {
        return (
          <Box display={"flex"} justifyContent={"space-around"}>
            {hasUpdateMasterDataPermission && (
              <>
                <Tooltip title="Generate Token">
                  <IconButton
                    color="primary"
                    onClick={() => {
                      generateCode(cellValues.row);
                    }}
                  >
                    <PlayCircleOutline />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Edit">
                  <IconButton
                    color="primary"
                    onClick={() => {
                      handleClickOpenEdit(cellValues.row);
                    }}
                  >
                    <EditOutlined />
                  </IconButton>
                </Tooltip>
              </>
            )}
            {hasDeleteMasterDataPermission && (
              <Tooltip title="Delete">
                <IconButton
                  color="error"
                  onClick={() => {
                    deleteOauth(cellValues.row._id);
                  }}
                >
                  <DeleteOutlined />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        );
      },
    },
  ];

  return (
    <Box m="1.5rem 2.5rem">
      <Box display={"flex"} justifyContent={"space-between"}>
        <Header title="Oauth" />
        <Box>
          {hasCreateMasterDataPermission && (
            <Button
              variant="contained"
              startIcon={<AddOutlined />}
              onClick={handleClickOpenCreate}
              sx={{
                bgcolor: theme.palette.secondary[400],
                "&:hover": {
                  backgroundColor: theme.palette.secondary[500],
                },
              }}
            >
              Create Oauth
            </Button>
          )}
          <CreateOauthDialogue
            handleClose={handleClose}
            open={open}
            oauthToUpdate={entityToUpdate}
          />
        </Box>
      </Box>
      <CustomDataGrid
        data={oauthList}
        columns={columns}
        isLoading={isLoading}
      />
      <AlertSnackbar
        open={snackbarOpen}
        handleClose={handleSnackbarClose}
        severity={"success"}
        message={"Successfully created token"}
      />
    </Box>
  );
};

export default Oauths;
