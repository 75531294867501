import React from "react";
import { AppState, RedirectLoginOptions, useAuth0 } from "@auth0/auth0-react";
import { Button, useTheme } from "@mui/material";

// todo: merge logout/login into one
const LogoutButton = () => {
  const theme = useTheme();
  const { logout } = useAuth0();
  return (
    <Button
      variant="contained"
      onClick={() =>
        logout({
          returnTo: window.location.origin,
        } as RedirectLoginOptions<AppState>)
      }
      sx={{
        bgcolor: theme.palette.secondary[500],
        "&:hover": {
          backgroundColor: theme.palette.secondary[600],
        },
      }}
    >
      Log Out
    </Button>
  );
};

export default LogoutButton;
