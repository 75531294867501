import React, { useReducer } from "react";
import { GridColDef } from "@mui/x-data-grid";
import { useGetLoadingsQuery } from "../../state/api";
import Header from "../../components/Header";
import {
  Autocomplete,
  Box,
  IconButton,
  TextField,
  Tooltip,
} from "@mui/material";
import { useNavigate, generatePath } from "react-router-dom";
import { InfoOutlined, RefreshOutlined } from "@mui/icons-material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { setBookingTeams, useUserPermissions } from "utils/utils";
import CustomDataGrid from "components/CustomDataGrid";
import EditOutlined from "@mui/icons-material/EditOutlined";
import { initialState, reducer } from "utils/dialogState";
import CreateLoadingDialog from "components/CreateLoadingDialog";
import { LoadingData } from "models/booking.model";
import { useDispatch, useSelector } from "react-redux";
import { setLoadingsFilters } from "state";

const Loadings = () => {
  const navigate = useNavigate();
  const dispatchLoadingState = useDispatch();

  const userPermissions = useUserPermissions();
  const hasExportPermission = userPermissions.includes("read:loadings-export");
  const hasImportPermission = userPermissions.includes("read:loadings-import");
  const teams = setBookingTeams(hasExportPermission, hasImportPermission);

  const [{ open, entityToUpdate }, dispatch] = useReducer(
    reducer<LoadingData>,
    initialState<LoadingData>()
  );

  const handleClickOpenEdit = (loadingData: LoadingData) => {
    dispatch({ type: "OPEN_DIALOG", payload: loadingData });
  };

  const handleClose = () => {
    dispatch({ type: "CLOSE_DIALOG" });
    refetch();
  };

  const { startDate, endDate, bookingTeam } = useSelector(
    (state: any) => state.global.loadingsFilters
  );

  const {
    data: loadings,
    isLoading,
    refetch,
    isFetching,
  } = useGetLoadingsQuery([
    [startDate, endDate],
    hasExportPermission,
    hasImportPermission,
    bookingTeam,
  ]);

  const columns: GridColDef[] = [
    {
      field: "bookingNumber",
      headerName: "Booking Number",
      flex: 0.4,
    },
    {
      field: "carrierBookingNumber",
      headerName: "Carrier Booking No",
      flex: 0.4,
    },
    {
      field: "consignor",
      headerName: "Consignor",
      flex: 0.4,
      valueGetter: (params) => {
        return params.row.consignor?.name;
      },
    },
    {
      field: "site",
      headerName: "Site",
      flex: 0.3,
    },
    {
      field: "date",
      headerName: "Date",
      flex: 0.3,
      valueGetter: (params) => {
        return dayjs(params.row.date).format("DD-MM-YYYY");
      },
    },
    {
      field: "time",
      headerName: "Time",
      flex: 0.2,
      valueGetter: (params) => {
        return dayjs(params.row.time).format("HH:mm");
      },
    },
    {
      field: "reference",
      headerName: "Reference",
      flex: 0.3,
    },
    {
      field: "containerNumber",
      headerName: "Container No",
      flex: 0.3,
    },
    {
      field: "sealNumber",
      headerName: "Seal Number",
      flex: 0.3,
    },
    {
      field: "weight",
      headerName: "Weight",
      flex: 0.2,
    },
    {
      field: "hauler",
      headerName: "Hauler",
      flex: 0.4,
    },
    {
      field: "remarks",
      headerName: "Remarks",
      flex: 0.5,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 0.2,
      // editable: true,
      // type: 'singleSelect',
      // valueOptions: transportStatuses.filter((s) => s !== TransportStatus.Completed)
    },
    {
      field: "Details",
      renderCell: (cellValues) => {
        return (
          <Box>
            <Tooltip title="View Booking">
              <IconButton
                color="primary"
                onClick={() => {
                  navigate(
                    generatePath(`/bookings/${cellValues.row.bookingId}`, {
                      id: cellValues.row.bookingId,
                    })
                  );
                }}
              >
                <InfoOutlined />
              </IconButton>
            </Tooltip>
            <Tooltip title="Edit">
              <IconButton
                color="primary"
                onClick={() => {
                  handleClickOpenEdit(cellValues.row);
                }}
              >
                <EditOutlined />
              </IconButton>
            </Tooltip>
          </Box>
        );
      },
      flex: 0.3,
    },
  ];

  /**
   * This has been commented to disallow inplace editing of status
   **/
  // const handleRowUpdate = async (data: any): Promise<void> => {
  //   const {hauler, site, containerNumber, reference, status, weight, bookingId}=data;
  //   const transportData: TransportSchedule = {
  //     _id: data._id,
  //     date: dayjs(data.date).format(),
  //     time: dayjs(data.time).format(),
  //     hauler,
  //     site,
  //     containerNumber,
  //     reference,
  //     status,
  //     weight
  //   }
  //   const accessToken = await sec.getAccessTokenSilently()();
  //   await axios.patch(
  //     `${process.env.REACT_APP_BASE_URL}/api/v1/bookings/${bookingId}?type=transportSchedule`,
  //     transportData,
  //     {
  //       headers: {
  //         Authorization: `Bearer ${accessToken}`,
  //       },
  //     }
  //   );
  //   refetch();
  // }

  return (
    <Box m="1.5rem 2.5rem">
      <Box display={"flex"} justifyContent={"space-between"}>
        <Header title="Loadings" />
        <Box display={"flex"} alignItems={"center"} gap={"1rem"}>
          <Tooltip title="Refresh">
            <IconButton onClick={() => refetch()}>
              <RefreshOutlined />
            </IconButton>
          </Tooltip>
          <Autocomplete
            options={teams ?? []}
            isOptionEqualToValue={(option, value) => option === value}
            value={bookingTeam}
            onChange={(e, newValue) => {
              if (newValue) {
                dispatchLoadingState(
                  setLoadingsFilters({ bookingTeam: newValue })
                );
              }
            }}
            renderInput={(params) => (
              <TextField {...params} label="Team" sx={{ width: "9rem" }} />
            )}
          />
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              sx={{ width: "9rem" }}
              label="Start Date"
              format="DD/MM/YYYY"
              value={dayjs(startDate)}
              onChange={(newDate: dayjs.Dayjs | null) => {
                if (newDate && newDate.isValid()) {
                  dispatchLoadingState(
                    setLoadingsFilters({ startDate: newDate.toISOString() })
                  );
                }
              }}
            />
            <DatePicker
              sx={{ width: "9rem" }}
              label="End Date"
              format="DD/MM/YYYY"
              value={dayjs(endDate)}
              onChange={(newDate: dayjs.Dayjs | null) => {
                if (newDate && newDate.isValid()) {
                  dispatchLoadingState(
                    setLoadingsFilters({ endDate: newDate.toISOString() })
                  );
                }
              }}
            />
          </LocalizationProvider>
        </Box>
      </Box>
      <CreateLoadingDialog
        handleClose={handleClose}
        open={open}
        loadingToUpdate={entityToUpdate}
      />
      <CustomDataGrid
        data={loadings}
        columns={columns}
        isLoading={isLoading}
        isFetching={isFetching}
        // onRowUpdate={handleRowUpdate}
      />
    </Box>
  );
};

export default Loadings;
