import React, { useState } from "react";
import axios from "axios";
import { useForm, useFieldArray } from "react-hook-form";
import {
  Box,
  Button,
  useTheme,
  TextField,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import RemoveOutlinedIcon from "@mui/icons-material/RemoveOutlined";
import { sec } from "../../auth/accessToken";
import { BaseDialogueProps } from "models/index.model";
import { TermsAndConditions } from "models/booking.model";
import AlertSnackbar from "components/AlertSnackbar";
import GeneralDialogueActions from "components/GeneralDialogueActions";
import { useGetAuditsQuery } from "state/api";

interface UpdateTermsAndConditionsProps extends BaseDialogueProps {
  id: string;
  termsAndConditions: TermsAndConditions;
  type: "booking" | "quotation";
}
const UpdateTermsAndConditions: React.FC<UpdateTermsAndConditionsProps> = ({
  handleClose,
  open,
  id,
  termsAndConditions,
  type,
}) => {
  const theme = useTheme();

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      termsAndConditions: termsAndConditions,
    },
  });

  const {
    fields: termsAndConditionsFields,
    append: termsAndConditionsAppend,
    remove: termsAndConditionsRemove,
  } = useFieldArray({
    control,
    name: "termsAndConditions",
  });

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const handleSnackbarClose = (event: any, reason: string) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbarOpen(false);
  };

  const { refetch: auditLogsRefetch } = useGetAuditsQuery(["Booking", id]);

  const onSubmit = async (data: { termsAndConditions: TermsAndConditions }) => {
    try {
      const accessToken = await sec.getAccessTokenSilently()();

      const payload = {
        [type === "booking"
          ? "bookingTermsAndConditions"
          : "quotationTermsAndConditions"]: data.termsAndConditions,
      };

      await axios.patch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/bookings/${id}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      handleClose();
      setSnackbarOpen(true);
      auditLogsRefetch();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Box>
      <Dialog open={open} onClose={() => handleClose()} fullWidth maxWidth="lg">
        <DialogTitle
          sx={{
            fontSize: "1.6rem",
          }}
        >
          Update Terms and Conditions
        </DialogTitle>
        <DialogContent>
          <form>
            <Box mb="1rem">
              <Button
                variant="contained"
                type="button"
                startIcon={<AddOutlinedIcon />}
                onClick={() =>
                  termsAndConditionsAppend({ termsAndCondition: "" })
                }
                sx={{
                  bgcolor: theme.palette.secondary[400],
                  mb: "1rem",
                  "&:hover": {
                    backgroundColor: theme.palette.secondary[500],
                  },
                }}
              >
                Add Term and Condition
              </Button>
            </Box>
            {termsAndConditionsFields.map((item, index) => {
              return (
                <Box
                  key={index}
                  display={"grid"}
                  gridTemplateColumns={"14fr 1fr"}
                  alignItems={"center"}
                  gap={"1rem"}
                  mb={"1rem"}
                >
                  <TextField
                    label="Terms and Conditions"
                    autoComplete="off"
                    fullWidth
                    {...register(
                      `termsAndConditions.${index}.termsAndCondition`,
                      {
                        required: true,
                      }
                    )}
                    error={
                      !!errors?.termsAndConditions?.[index]?.termsAndCondition
                    }
                    helperText={
                      errors?.termsAndConditions?.[index]?.termsAndCondition
                        ?.message
                    }
                  />
                  <Box>
                    <IconButton
                      type="button"
                      onClick={() => termsAndConditionsRemove(index)}
                      sx={{
                        bgcolor: theme.palette.secondary[400],
                        "&:hover": {
                          backgroundColor: theme.palette.secondary[500],
                        },
                      }}
                    >
                      <RemoveOutlinedIcon />
                    </IconButton>
                  </Box>
                </Box>
              );
            })}
          </form>
        </DialogContent>
        <GeneralDialogueActions
          onClick={handleSubmit(onSubmit)}
          handleClose={handleClose}
          submitText="Update"
        />
      </Dialog>
      <AlertSnackbar
        open={snackbarOpen}
        handleClose={handleSnackbarClose}
        severity={"success"}
        message={"Terms and Conditions updated successfully !"}
      />
    </Box>
  );
};

export default UpdateTermsAndConditions;
