import React from "react";
import MenuItem from "@mui/material/MenuItem";

// Function to map options to MenuItem components
export function generateMenuItemsFromArray(options: string[]) {
  return options.map((option, idx) => (
    <MenuItem key={idx} value={option}>
      {option}
    </MenuItem>
  ));
}
