import React, { useState } from "react";
import axios from "axios";
import {
  useForm,
  useFieldArray,
  Controller,
  UseFormReturn,
} from "react-hook-form";
import {
  Box,
  Button,
  useTheme,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Autocomplete,
  FormControlLabel,
  Checkbox,
  Divider,
} from "@mui/material";
import { sec } from "../../auth/accessToken";
import {
  currencies,
  bases,
  chargeBaseMapping,
  chargeBaseMappingKeys,
} from "../../dataAssets/constants";
import { generateMenuItemsFromArray } from "../../utils/generateMenuItemsFromArray";
import { BaseDialogueProps } from "models/index.model";
import {
  AdditionalCharge,
  Booking,
  Charge,
  Invoice,
} from "models/booking.model";
import AlertSnackbar from "components/AlertSnackbar";
import GeneralDialogueActions from "components/GeneralDialogueActions";
import { BaseIsolatedReRender } from "utils/renderBase";
import { useGetAuditsQuery } from "state/api";

interface UpdateAdditionalChargesDialogueProps extends BaseDialogueProps {
  id: string;
  additionalBuyRates: Charge[] | undefined;
  additionalSellRates: AdditionalCharge[] | undefined;
}

const UpdateAdditionalChargesDialogue: React.FC<
  UpdateAdditionalChargesDialogueProps
> = ({ handleClose, open, id, additionalBuyRates, additionalSellRates }) => {
  const theme = useTheme();

  const { refetch: auditLogsRefetch } = useGetAuditsQuery(["Booking", id]);

  const currentForm = useForm<Booking>({
    defaultValues: {
      additionalBuyRates: additionalBuyRates || [],
      additionalSellRates: additionalSellRates || [],
    },
  });

  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
  } = currentForm;

  const {
    fields: additionalBuyRatesFields,
    append: appendAdditionalBuyRate,
    remove: removeAdditionalBuyRate,
  } = useFieldArray({
    control: control,
    name: "additionalBuyRates",
  });

  const {
    fields: additionalSellRatesFields,
    append: appendAdditionalSellRate,
    remove: removeAdditionalSellRate,
  } = useFieldArray({
    control: control,
    name: "additionalSellRates",
  });

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const handleSnackbarClose = (event: any, reason: string) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbarOpen(false);
  };

  const onSubmit = async (data: Booking) => {
    try {
      const accessToken = await sec.getAccessTokenSilently()();

      await axios.patch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/bookings/${id}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      handleClose();
      setSnackbarOpen(true);
      auditLogsRefetch();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Box>
      <Dialog open={open} onClose={() => handleClose()} fullWidth maxWidth="xl">
        <DialogTitle
          sx={{
            fontSize: "1.6rem",
          }}
        >
          {"Update Additional Charges"}
        </DialogTitle>
        <DialogContent>
          <form>
            <Box display={"flex"} justifyContent={"space-between"}>
              <Typography variant="h5">Additional Buy Rates</Typography>
              <Button
                variant="contained"
                type="button"
                onClick={() => appendAdditionalBuyRate({} as Charge)}
                sx={{
                  bgcolor: theme.palette.secondary[400],
                  "&:hover": {
                    backgroundColor: theme.palette.secondary[500],
                  },
                }}
              >
                Add
              </Button>
            </Box>
            <Box m={"1rem 0"}>
              {additionalBuyRatesFields.map((item, index) => {
                return (
                  <Box
                    display="grid"
                    gridTemplateColumns={"70fr 7fr 10fr 10fr 1fr"}
                    alignItems={"center"}
                    gap={"1rem"}
                    mt={"1rem"}
                    key={item.id}
                  >
                    <Controller
                      name={`additionalBuyRates.${index}.chargeName`}
                      control={control}
                      defaultValue={chargeBaseMappingKeys[0]}
                      render={({ field: { value, onChange } }) => (
                        <Autocomplete
                          freeSolo
                          autoSelect
                          value={value}
                          onChange={(_, value) => onChange(value)}
                          options={chargeBaseMappingKeys}
                          renderInput={(params) => (
                            <TextField {...params} label="Charge Name *" />
                          )}
                        />
                      )}
                    />
                    <Controller
                      name={`additionalBuyRates.${index}.currency`}
                      control={control}
                      defaultValue={currencies[0]}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          select
                          autoComplete="off"
                          label="Currency *"
                        >
                          {generateMenuItemsFromArray(currencies)}
                        </TextField>
                      )}
                    />
                    <TextField
                      autoComplete="off"
                      type="number"
                      label="Value *"
                      defaultValue={0}
                      {...register(`additionalBuyRates.${index}.rate`, {
                        required: "Value is required",
                      })}
                      error={!!errors?.buyRates?.[index]?.rate}
                      helperText={errors?.buyRates?.[index]?.rate?.message}
                    />
                    <BaseIsolatedReRender
                      form={
                        currentForm as UseFormReturn<
                          Invoice | Booking,
                          any,
                          Invoice | Booking
                        >
                      }
                      index={index}
                      chargeBaseMapping={chargeBaseMapping}
                      bases={bases}
                      formPath="additionalBuyRates"
                    />

                    <Button
                      variant="contained"
                      type="button"
                      onClick={() => removeAdditionalBuyRate(index)}
                      sx={{
                        bgcolor: theme.palette.secondary[400],
                        "&:hover": {
                          backgroundColor: theme.palette.secondary[500],
                        },
                      }}
                    >
                      Delete
                    </Button>
                  </Box>
                );
              })}
            </Box>
            <Divider
              sx={{
                margin: "1rem 0",
              }}
            />
            <Box display={"flex"} justifyContent={"space-between"}>
              <Typography variant="h5">Additional Sell Rates</Typography>
              <Button
                variant="contained"
                type="button"
                onClick={() => appendAdditionalSellRate({} as AdditionalCharge)}
                sx={{
                  bgcolor: theme.palette.secondary[400],
                  "&:hover": {
                    backgroundColor: theme.palette.secondary[500],
                  },
                }}
              >
                Add
              </Button>
            </Box>
            <Box m={"1rem 0"}>
              {additionalSellRatesFields.map((item, index) => {
                return (
                  <Box
                    display="grid"
                    gridTemplateColumns={"70fr 7fr 10fr 10fr 3fr 1fr"}
                    alignItems={"center"}
                    gap={"1rem"}
                    mt={"1rem"}
                    key={item.id}
                  >
                    <Controller
                      name={`additionalSellRates.${index}.chargeName`}
                      control={control}
                      defaultValue={chargeBaseMappingKeys[0]}
                      render={({ field: { value, onChange } }) => (
                        <Autocomplete
                          freeSolo
                          autoSelect
                          value={value}
                          onChange={(_, value) => onChange(value)}
                          options={chargeBaseMappingKeys}
                          renderInput={(params) => (
                            <TextField {...params} label="Charge Name *" />
                          )}
                        />
                      )}
                    />
                    <Controller
                      name={`additionalSellRates.${index}.currency`}
                      control={control}
                      defaultValue={currencies[0]}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          select
                          autoComplete="off"
                          label="Currency *"
                        >
                          {generateMenuItemsFromArray(currencies)}
                        </TextField>
                      )}
                    />
                    <TextField
                      autoComplete="off"
                      type="number"
                      label="Value *"
                      defaultValue={0}
                      {...register(`additionalSellRates.${index}.rate`, {
                        required: "Value is required",
                      })}
                      error={!!errors?.buyRates?.[index]?.rate}
                      helperText={errors?.buyRates?.[index]?.rate?.message}
                    />
                    <BaseIsolatedReRender
                      form={
                        currentForm as UseFormReturn<
                          Invoice | Booking,
                          any,
                          Invoice | Booking
                        >
                      }
                      index={index}
                      chargeBaseMapping={chargeBaseMapping}
                      bases={bases}
                      formPath="additionalSellRates"
                    />
                    <Controller
                      control={control}
                      name={`additionalSellRates.${index}.raised`}
                      render={({ field: { value, onChange } }) => (
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={value}
                              onChange={(e) =>
                                onChange(e.target.checked ? true : false)
                              }
                            />
                          }
                          label="Raised"
                        />
                      )}
                    />
                    <Button
                      variant="contained"
                      type="button"
                      onClick={() => removeAdditionalSellRate(index)}
                      sx={{
                        bgcolor: theme.palette.secondary[400],
                        "&:hover": {
                          backgroundColor: theme.palette.secondary[500],
                        },
                      }}
                    >
                      Delete
                    </Button>
                  </Box>
                );
              })}
            </Box>
          </form>
        </DialogContent>
        <GeneralDialogueActions
          onClick={handleSubmit(onSubmit)}
          handleClose={handleClose}
          submitText="Update"
        />
      </Dialog>
      <AlertSnackbar
        open={snackbarOpen}
        handleClose={handleSnackbarClose}
        severity={"success"}
        message={"Additional Charges Updated Successfully !"}
      />
    </Box>
  );
};

export default UpdateAdditionalChargesDialogue;
