import React from "react";
import { Box, IconButton, Tooltip } from "@mui/material";
import {
  EditOutlined,
  DeleteOutlined,
  RecentActorsOutlined,
} from "@mui/icons-material";
import { GridCellParams } from "@mui/x-data-grid";
import ConfirmDialogue from "components/ConfirmDialogue";

export function generateActionsColumn<T>(
  hasUpdatePermission: boolean,
  hasDeletePermission: boolean,
  handleClickOpenEdit: (masterData: T) => void,
  setOpenAuditDialogue: React.Dispatch<React.SetStateAction<boolean>>,
  deleteFunction: (id: string) => void,
  openConfirmDialogue: boolean,
  setOpenConfirmDialogue: React.Dispatch<React.SetStateAction<boolean>>,
  currentMasterDataId: string,
  setCurrentMasterDataId: React.Dispatch<React.SetStateAction<string>>
) {
  return {
    field: "Actions",
    headerName: "Actions",
    flex: 0.3,
    renderCell: (cellValues: GridCellParams) => {
      return (
        <Box display={"flex"} justifyContent={"space-around"}>
          {hasUpdatePermission && (
            <Tooltip title="Edit">
              <IconButton
                color="primary"
                onClick={() => {
                  handleClickOpenEdit(cellValues.row);
                }}
              >
                <EditOutlined />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip title="Audit Logs">
            <IconButton
              color="primary"
              onClick={() => {
                setCurrentMasterDataId(cellValues.row._id as string);
                setOpenAuditDialogue(true);
              }}
            >
              <RecentActorsOutlined />
            </IconButton>
          </Tooltip>
          {hasDeletePermission && (
            <>
              <Tooltip title="Delete">
                <IconButton
                  color="error"
                  onClick={() => {
                    setCurrentMasterDataId(cellValues.row._id as string);
                    setOpenConfirmDialogue(true);
                  }}
                >
                  <DeleteOutlined />
                </IconButton>
              </Tooltip>
              <ConfirmDialogue
                open={openConfirmDialogue}
                handleClose={() => setOpenConfirmDialogue(false)}
                title="Delete"
                content="Are you sure you want to delete this item?"
                onConfirm={() => {
                  deleteFunction(currentMasterDataId);
                  setOpenConfirmDialogue(false);
                }}
              />
            </>
          )}
        </Box>
      );
    },
  };
}
