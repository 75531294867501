import React from "react";
import { Box, Dialog, DialogContent, DialogTitle } from "@mui/material";
import { Booking } from "models/booking.model";
import { BaseDialogueProps } from "models/index.model";
import GeneralDialogueActions from "components/GeneralDialogueActions";
import InvoiceReportTable from "components/InvoiceReportTable";

export interface InvoiceReportDialogueProps extends BaseDialogueProps {
  booking: Booking;
  currentBookingIncome: Booking["bookingIncome"];
}

const InvoiceReportDialogue: React.FC<InvoiceReportDialogueProps> = ({
  handleClose,
  open,
  booking,
  currentBookingIncome,
}) => {
  const allInvoices = booking.salesInvoices.concat(
    booking.purchaseInvoices,
    booking.creditNotes,
    booking.debitNotes
  );
  return (
    <Box>
      <Dialog open={open} onClose={() => handleClose()} fullScreen>
        <DialogTitle
          sx={{
            fontSize: "1.6rem",
          }}
        >
          Invoicing Report
        </DialogTitle>
        <DialogContent>
          {allInvoices.length > 0 ? (
            <Box>
              <InvoiceReportTable
                invoices={allInvoices}
                booking={booking}
                currentBookingIncome={currentBookingIncome}
              />
            </Box>
          ) : (
            <>No Invoices Created</>
          )}
        </DialogContent>
        <GeneralDialogueActions handleClose={handleClose} noSubmission />
      </Dialog>
    </Box>
  );
};

export default InvoiceReportDialogue;
