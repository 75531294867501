import React from "react";
import { Box, Dialog, DialogContent, DialogTitle } from "@mui/material";
import { PDFViewer } from "@react-pdf/renderer";
import { BaseDialogueProps } from "models/index.model";
import GeneralDialogueActions from "components/GeneralDialogueActions";
import TransportScheduleDocument from "components/TransportScheduleDocument";
import { TransportSchedule } from "models/booking.model";

export interface TransportScheduleViewerProps extends BaseDialogueProps {
  booking: any;
  transportSchedule: TransportSchedule[];
}

const TransportScheduleViewer: React.FC<TransportScheduleViewerProps> = ({
  handleClose,
  open,
  booking,
  transportSchedule,
}) => {
  return (
    <Box>
      <Dialog open={open} onClose={() => handleClose()} fullScreen>
        <DialogTitle
          sx={{
            fontSize: "1.6rem",
          }}
        >
          {`Transport Schedule: ${booking.bookingNumber}`}
        </DialogTitle>
        <DialogContent>
          <PDFViewer style={{ width: "100%", height: "100%" }}>
            <TransportScheduleDocument
              booking={booking}
              transportSchedule={transportSchedule}
            />
          </PDFViewer>
        </DialogContent>
        <GeneralDialogueActions handleClose={handleClose} noSubmission />
      </Dialog>
    </Box>
  );
};

export default TransportScheduleViewer;
