import React, { useState } from "react";
import DataGridCustomToolbar from "../DataGridCustomToolbar";
import { Box, useTheme } from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridColumnVisibilityModel,
} from "@mui/x-data-grid";
import AlertSnackbar from "components/AlertSnackbar";
import { AlertColor } from "@mui/material/Alert";

export interface CustomDatagridProps {
  data: any;
  columns: GridColDef[];
  isLoading: boolean;
  isFetching?: boolean;
  columnVisibilityModel?: GridColumnVisibilityModel;
  handleColumnVisibilityChange?: (model: GridColumnVisibilityModel) => void;
  showToolbar?: boolean;
  height?: string;
  onRowUpdate?: (newVal: any) => void;
  sortModel?: any[];
}

interface SnackbarState {
  open: boolean;
  color: AlertColor;
  message: string;
}

const CustomDataGrid: React.FC<CustomDatagridProps> = ({
  data,
  columns,
  isLoading,
  isFetching,
  columnVisibilityModel,
  handleColumnVisibilityChange,
  showToolbar = true,
  height = "75vh",
  onRowUpdate,
  sortModel = [],
}) => {
  const defaultSnackbarState: SnackbarState = {
    open: false,
    color: "success",
    message: "Unable to update row",
  };
  const theme = useTheme();
  const [snackbarOpen, setSnackbarOpen] =
    useState<SnackbarState>(defaultSnackbarState);
  return (
    <Box
      height={height}
      sx={{
        "& .MuiDataGrid-root": {
          border: "none",
        },
        "& .MuiDataGrid-cell": {
          borderBottom: "none",
        },
        "& .MuiDataGrid-columnHeaders": {
          backgroundColor: theme.palette.secondary[300],
          color: theme.palette.secondary[700],
          borderBottom: "none",
          borderRadius: "5px 5px 0 0",
        },
        "& .MuiDataGrid-virtualScroller": {
          backgroundColor: theme.palette.secondary[100],
          color: theme.palette.secondary[700],
        },
        "& .MuiDataGrid-footerContainer": {
          backgroundColor: theme.palette.secondary[300],
          color: theme.palette.secondary[700],
          borderRadius: "0 0 5px 5px",
          borderTop: "none",
        },
        "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
          color: `${theme.palette.secondary[200]} !important`,
        },
      }}
    >
      <DataGrid
        loading={isLoading || !data || isFetching}
        getRowId={(row) => row._id}
        rows={data ?? []}
        columns={columns}
        slots={showToolbar ? { toolbar: DataGridCustomToolbar } : {}}
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={handleColumnVisibilityChange}
        processRowUpdate={(newVal, oldVal) => {
          if (typeof onRowUpdate === "undefined") {
            setSnackbarOpen({
              open: true,
              message: "onRowUpdate method is not implemented",
              color: "error",
            });
            return oldVal;
          }
          try {
            onRowUpdate(newVal);
          } catch (e) {
            if (e instanceof Error) {
              setSnackbarOpen({
                open: true,
                message: e.message,
                color: "error",
              });
            }
            return oldVal;
          }
          setSnackbarOpen({
            open: true,
            message: "Successfully updated",
            color: "success",
          });
          return newVal;
        }}
        onProcessRowUpdateError={(err) => {
          setSnackbarOpen({
            open: true,
            message: err.message,
            color: "error",
          });
        }}
        initialState={{
          sorting: {
            sortModel,
          },
        }}
        density="compact"
      />
      <AlertSnackbar
        open={snackbarOpen.open}
        handleClose={() => setSnackbarOpen(defaultSnackbarState)}
        severity={snackbarOpen.color}
        message={snackbarOpen.message}
      />
    </Box>
  );
};

export default CustomDataGrid;
