import {
  Box,
  Dialog,
  DialogActions,
  Button,
  useTheme,
  DialogTitle,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import { BaseDialogueProps } from "models/index.model";
import React from "react";

export interface ActionDialogueProps extends BaseDialogueProps {
  handleCompletedManually?: Function;
  apiHandler?: Function;
}

const ActionDialogue: React.FC<ActionDialogueProps> = ({
  open,
  handleClose,
  handleCompletedManually,
  apiHandler,
}) => {
  const theme = useTheme();
  const onCloseAction = () => handleClose();
  return (
    <Box>
      <Dialog open={open} onClose={onCloseAction}>
        <DialogTitle id="alert-dialog-title">
          {"Confirm Action Type"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <>
              <span>This action can be completed manually or via an API.</span>
              <span>
                Clicking either option will mark this action as completed.
              </span>
            </>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={typeof handleCompletedManually === "undefined"}
            onClick={() => handleCompletedManually!()}
            sx={{
              color: theme.palette.primary[700],
              bgcolor: theme.palette.secondary[200],
              "&:hover": { bgcolor: theme.palette.secondary[300] },
            }}
          >
            Completed Manually
          </Button>
          <Button
            disabled={typeof apiHandler === "undefined"}
            onClick={() => apiHandler!()}
            sx={{
              color: theme.palette.primary[700],
              bgcolor: theme.palette.secondary[200],
              "&:hover": { bgcolor: theme.palette.secondary[300] },
            }}
          >
            API
          </Button>
          <Button
            onClick={onCloseAction}
            sx={{
              color: theme.palette.primary[700],
              bgcolor: theme.palette.secondary[100],
              "&:hover": { bgcolor: theme.palette.secondary[200] },
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ActionDialogue;
