import React from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert, { AlertColor } from "@mui/material/Alert";
import { BaseDialogueProps } from "models/index.model";

export interface AlertSnackbarProps extends BaseDialogueProps {
  severity: AlertColor;
  message: string;
}

const AlertSnackbar: React.FC<AlertSnackbarProps> = ({
  open,
  handleClose,
  severity,
  message,
}) => {
  return (
    <div>
      <Snackbar
        open={open}
        autoHideDuration={5000}
        onClose={() => handleClose()}
      >
        <Alert
          onClose={() => handleClose()}
          severity={severity}
          variant="filled"
          sx={{ whiteSpace: 'pre-line' }}
        >
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default AlertSnackbar;
