import axios from "axios";
import { sec } from "auth/accessToken";
import { Booking, BookingStatus } from "models/booking.model";

export const duplicateBooking = async (bookingId: String) => {
  try {
    const accessToken = await sec.getAccessTokenSilently()();

    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/api/v1/bookings/${bookingId}`,
      null,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data.id;
  } catch (error) {
    console.error(error);
  }
};

export const deleteBooking = async (
  bookingId: String,
  bookingNumber: String
) => {
  try {
    const accessToken = await sec.getAccessTokenSilently()();

    await axios.delete(
      `${process.env.REACT_APP_BASE_URL}/api/v1/bookings/${bookingId}/?bookingNumber=${bookingNumber}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
  } catch (error) {
    console.error(error);
  }
};

export const completeBooking = async (
  bookingId: String,
  bookingIncome: Booking["bookingIncome"]
) => {
  try {
    const accessToken = await sec.getAccessTokenSilently()();

    await axios.patch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/bookings/${bookingId}`,
      {
        bookingStatus: BookingStatus.Completed,
        bookingIncome,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
  } catch (error) {
    console.error(error);
  }
};

export const reopenBooking = async (bookingId: String) => {
  try {
    const accessToken = await sec.getAccessTokenSilently()();

    await axios.patch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/bookings/${bookingId}`,
      {
        bookingStatus: BookingStatus.Ongoing,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
  } catch (error) {
    console.error(error);
  }
};

export const cancelBooking = async (bookingId: String) => {
  try {
    const accessToken = await sec.getAccessTokenSilently()();

    await axios.patch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/bookings/${bookingId}`,
      {
        bookingStatus: "Cancelled",
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
  } catch (error) {
    console.error(error);
  }
};
