import { Box, CircularProgress, useTheme } from "@mui/material";
import { Booking } from "models/booking.model";
import React from "react";
import Chart from "react-apexcharts";

export interface BarChartProps {
  savedBookingIncome: Booking["bookingIncome"];
  currentBookingIncome: Booking["bookingIncome"];
  currentBookingLoading: boolean;
}

const BarChart: React.FC<BarChartProps> = ({
  savedBookingIncome,
  currentBookingIncome,
  currentBookingLoading,
}) => {
  const theme = useTheme();

  const data = savedBookingIncome
    ? Object.values(savedBookingIncome)
    : currentBookingIncome
    ? Object.values(currentBookingIncome)
    : [0, 0, 0];

  return (
    <Box style={{ height: "70vh" }}>
      {!currentBookingLoading ? (
        <Chart
          series={[
            {
              data: data.map(Number),
            },
          ]}
          type="bar"
          height="100%"
          options={{
            chart: {
              toolbar: {
                show: false,
              },
            },
            colors: [theme.palette.secondary[500]],
            plotOptions: {
              bar: {
                borderRadius: 4,
                horizontal: false,
                columnWidth: "45%",
              },
            },
            dataLabels: {
              enabled: true,
            },
            grid: {
              show: false,
              borderColor: theme.palette.secondary[200],
            },
            xaxis: {
              categories: ["Revenue", "Cost", "Profit"],
              labels: {
                style: {
                  colors: theme.palette.secondary[100],
                },
              },
            },
            yaxis: {
              title: {
                text: "GBP (£)",
                style: { color: theme.palette.secondary[100] },
              },
              labels: {
                style: {
                  colors: theme.palette.secondary[100],
                },
                formatter(val) {
                  return `${val.toFixed(0)}`;
                },
              },
            },
            fill: {
              opacity: 1,
            },
            tooltip: {
              enabled: false,
            },
          }}
        />
      ) : (
        <>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            minHeight="75vh"
          >
            <CircularProgress size={"10rem"} />
          </Box>
        </>
      )}
    </Box>
  );
};

export default BarChart;
