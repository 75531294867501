import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { sec } from "../auth/accessToken";
import {
  AuditLog,
  Booking,
  DeadlineType,
  Deadlines,
  Invoice,
  TeamType,
} from "models/booking.model";
import { Customer, Vendor, Site, Port, Oauth } from "models/index.model";

export const api = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
    prepareHeaders: async (headers) => {
      const access_token = await sec.getAccessTokenSilently()();
      if (access_token) {
        headers.set("Authorization", `Bearer ${access_token}`);
      }
      return headers;
    },
  }),
  reducerPath: "adminApi",
  tagTypes: [
    "User",
    "Bookings",
    "Booking",
    "Customers",
    "Vendors",
    "Ports",
    "Deadlines",
    "Loadings",
    "Sites",
    "Oauth",
    "CustomerDetails",
    "Documents",
    "Files",
    "Invoices",
    "ExchangeRates",
    "Profit",
    "Outstanding",
    "Audits",
  ],
  endpoints: (build) => ({
    getUser: build.query<any, string>({
      query: (id) => `api/v1/users/${id}`,
      providesTags: ["User"],
    }),
    getBookings: build.query<
      Booking[],
      {
        hasExportPermission?: string;
        hasImportPermission?: string;
        dateRange?: [string, string];
        bookingStatus?: string;
        bookingTeam?: TeamType;
        consignor?: string;
        consignee?: string;
        carrier?: string;
        vesselName?: string;
        voyageNumber?: string;
        portOfLoading?: string;
        portOfDestination?: string;
        placeOfDelivery?: string;
        containerType?: string;
        salesInvoiceCreated?: string;
        purchaseInvoiceCreated?: string;
        blStatus?: string;
        outstandingAdditionalCharges?: string;
      }
    >({
      query: ({
        hasExportPermission,
        hasImportPermission,
        dateRange,
        bookingStatus,
        bookingTeam,
        consignor,
        consignee,
        carrier,
        vesselName,
        voyageNumber,
        portOfLoading,
        portOfDestination,
        placeOfDelivery,
        containerType,
        salesInvoiceCreated,
        purchaseInvoiceCreated,
        blStatus,
        outstandingAdditionalCharges,
      }) => {
        const params: { [key: string]: string | undefined } = {
          hasExportPermission,
          hasImportPermission,
          dateRange: dateRange?.join(","),
          bookingStatus,
          bookingTeam: bookingTeam !== "All" ? bookingTeam : undefined,
          consignor,
          consignee,
          carrier,
          vesselName,
          voyageNumber,
          portOfLoading,
          portOfDestination,
          placeOfDelivery,
          containerType,
          salesInvoiceCreated,
          purchaseInvoiceCreated,
          blStatus,
          outstandingAdditionalCharges,
        };

        const queryParams = Object.entries(params).reduce(
          (acc, [key, value]) => {
            if (value !== undefined) {
              acc[key] = value;
            }
            return acc;
          },
          {} as Record<string, string>
        );

        const searchParams = new URLSearchParams(queryParams);

        return `api/v1/bookings/?${searchParams.toString()}`;
      },
      providesTags: ["Bookings"],
    }),
    getBooking: build.query<Booking, string>({
      query: (id) => `api/v1/bookings/${id}`,
      providesTags: ["Booking"],
    }),
    getCustomers: build.query<Customer[], void>({
      query: () => `api/v1/customers/`,
      providesTags: ["Customers"],
    }),
    getVendors: build.query<Vendor[], void>({
      query: () => `api/v1/vendors/`,
      providesTags: ["Vendors"],
    }),
    getPorts: build.query<Port[], void>({
      query: () => `api/v1/ports/`,
      providesTags: ["Ports"],
    }),
    getDeadlines: build.query<
      Deadlines[],
      [DeadlineType, [string, string], boolean, boolean, TeamType]
    >({
      query: ([
        deadlineType,
        dateRange,
        hasExportPermission,
        hasImportPermission,
        bookingTeam,
      ]) =>
        `api/v1/bookings/deadlines/today/${deadlineType}/?dateRange=${dateRange}&hasExportPermission=${hasExportPermission}&hasImportPermission=${hasImportPermission}&bookingTeam=${bookingTeam}`,
      providesTags: ["Deadlines"],
    }),
    getLoadings: build.query<
      any,
      [[string, string], boolean, boolean, TeamType]
    >({
      query: ([
        dateRange,
        hasExportPermission,
        hasImportPermission,
        bookingTeam,
      ]) =>
        `api/v1/bookings/loadings/today/?dateRange=${dateRange}&hasExportPermission=${hasExportPermission}&hasImportPermission=${hasImportPermission}&bookingTeam=${bookingTeam}`,
      providesTags: ["Loadings"],
    }),
    getSites: build.query<Site[], void>({
      query: () => `api/v1/sites/`,
      providesTags: ["Sites"],
    }),
    getOauth: build.query<Oauth[], void>({
      query: () => `api/v1/oauth/`,
      providesTags: ["Oauth"],
    }),
    getCustomerDetails: build.query<Customer[], string>({
      query: (name) => `api/v1/customers/${name}`,
      providesTags: ["CustomerDetails"],
    }),
    getDocuments: build.query<any, any>({
      query: (key) => `api/v1/documents/?key=${key}`,
      providesTags: ["Documents"],
    }),
    getFiles: build.query<any, string | undefined>({
      query: (bookingNumber) => {
        return `api/v1/documents/${bookingNumber}`;
      },
      providesTags: ["Files"],
    }),
    getInvoices: build.query<
      Invoice[],
      [string, boolean, boolean, boolean, [string, string], string, TeamType]
    >({
      query: ([
        invoiceType,
        pendingApproval,
        invoiceOutstanding,
        underDispute,
        dateRange,
        dateType,
        bookingTeam,
      ]) =>
        `api/v1/${invoiceType}/?approvalPending=${pendingApproval}&invoiceOutstanding=${invoiceOutstanding}&underDispute=${underDispute}&dateRange=${dateRange}&dateType=${dateType}&bookingTeam=${bookingTeam}`,
      providesTags: ["Invoices"],
    }),
    getExchangeRate: build.query<any, [string, string]>({
      query: ([base, target]) =>
        `api/v1/exchangeRates/?base=${base}&target=${target}`,
      providesTags: ["ExchangeRates"],
    }),
    getBookingProfit: build.query<any, string>({
      query: (bookingId) => `api/v1/bookings/analytics/profit/${bookingId}`,
      providesTags: ["Profit"],
    }),
    getExpectedProfit: build.query<any, string>({
      query: (bookingId) =>
        `api/v1/bookings/analytics/expectedProfit/${bookingId}`,
      providesTags: ["Profit"],
    }),
    getCustomerOutstandingAmount: build.query<Number, string | undefined>({
      query: (customerId) => `api/v1/customers/outstanding/${customerId}`,
      providesTags: ["Outstanding"],
    }),
    getAudits: build.query<AuditLog[], [string, string]>({
      query: ([resource, resourceId]) =>
        `api/v1/audits/?resource=${resource}&resourceId=${resourceId}`,
      providesTags: ["Audits"],
    }),
  }),
});

export const {
  useGetUserQuery,
  useGetBookingsQuery,
  useGetBookingQuery,
  useGetCustomersQuery,
  useGetVendorsQuery,
  useGetPortsQuery,
  useGetDeadlinesQuery,
  useGetLoadingsQuery,
  useGetSitesQuery,
  useGetOauthQuery,
  useGetCustomerDetailsQuery,
  useGetDocumentsQuery,
  useGetFilesQuery,
  useGetInvoicesQuery,
  useGetExchangeRateQuery,
  useGetBookingProfitQuery,
  useGetExpectedProfitQuery,
  useGetCustomerOutstandingAmountQuery,
  useGetAuditsQuery,
} = api;

export default api.endpoints;
