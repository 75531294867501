import {
  Booking,
  BookingStatus,
  HaulageType,
  TeamType,
  TransportStatus,
} from "models/booking.model";

export const containerTypes: string[] = [
  "40 Dry High",
  "20 Dry Standard",
  "20 Open Top",
  "40 Dry Standard",
  "45 Dry High",
  "20 Tank",
  "40 Tank",
  "20 Reefer Standard",
  "40 Reefer High",
  "40 Reefer Standard",
  "40 Open Top",
  "40 Open Top High",
  "40 Flat Standard",
  "40 Flat High",
  "20 Flat",
];

export const chargeBaseMapping = {
  "OCEAN FREIGHT": "CN",
  "DOCUMENTATION CHARGE": "BL",
  "CUSTOMS CLEARANCE": "BL",
  "EXPORT DECLARATION CHARGE": "BL",
  "EU MANIFEST CHARGE": "BL",
  "VGM CHARGE": "CN",
  "TERMINAL VGM CHARGE": "CN",
  "VGM MISDECLARATION CHARGE": "CN",
  "LATE VGM SUBMISSION CHARGE": "CN",
  "HAULAGE CHARGE": "CN",
  "EA EXAMINATION CHARGE": "CN",
  "BL AMENDMENT CHARGE": "BL",
  "BL TYPE AMENDMENT CHARGE": "BL",
  "LATE BL CHARGE": "BL",
  "MANIFEST CORRECTION CHARGE": "BL",
  "SPLIT BL CHARGE": "BL",
  "COURIER CHARGE": "BL",
  "VESSEL CERTIFICATE CHARGE": "BL",
  "FREETIME CERTIFICATE CHARGE": "BL",
  "STORAGE CHARGE": "CN",
  "DETENTION CHARGE": "CN",
  "WAITING TIME CHARGE": "CN",
  "WASTED JOURNEY CHARGE": "CN",
  "LIFT ON LIFT OFF CHARGE": "CN",
  "D&D CHARGE": "CN",
  "INSURANCE CHARGE": "BL",
  "TELEX CHARGE": "BL",
  "ANNEX VII REPLACEMENT CHARGE": "CN",
  "BOOKING AMENDMENT CHARGE": "CN",
  "CONTAINER REDIRECTION CHARGE": "CN",
  "CONTAINER DAMAGE / REPAIR CHARGE": "CN",
  "LOW SULPHUR SURCHARGE (LSS)": "CN",
  "PEAK SEASON SURCHARGE (PSS)": "CN",
  "EMERGENCY CONTINGENCY SURCHARGE (ECS)": "CN",
  "TRANSIT DISRUPTION SURCHARGE (TDS )": "CN",
  "EU EMISSION TRADING SYSTEM (ETS)": "CN",
  "SPOT BOOKING AMENDMENT CHARGE": "CN",
  "SPOT BOOKING NO SHOW CHARGE": "CN",
  "CONTAINER CLEANING CHARGE": "CN",
  "CONTAINER MAINTENANCE CHARGE": "CN",
  "LOLO CHARGE": "CN",
  "TERMINAL HANDLING CHARGE ORIGIN": "CN",
  "TERMINAL HANDLING CHARGE DESTINATION": "CN",
  "CHANGE OF DESTINATION CHARGE": "CN",
  "CONTAINER RESTOW CHARGE": "CN",
  "OVERWEIGHT SURCHARGE": "CN",
  ISPS: "CN",
  "SEAL CHARGE": "CN",
  "PAYER AMENDMENT CHARGE": "BL",
  "AVANTIDA CHARGE": "BL",
  "CERTIFICATE OF ORIGIN CHARGE": "BL",
  "EUR1 CHARGE": "BL",
  "HS CODE CHARGE": "BL",
  "ADDITIONAL HS CODE": "BL",
  "SHUNT CHARGE": "CN",
  "VESSEL CHANGE CHARGE": "CN",
  VAT: "CN",
};

export const chargeBaseMappingKeys = Object.keys(chargeBaseMapping);

export const reudanAddress = `Office 102, 15 Main Drive, East Lane Business Park, Wembley, HA9 7NA
              Tel: +44 (0) 208 904 9616`;

export const currencies: string[] = ["USD", "GBP", "EUR"];

export const bases: string[] = ["CN", "BL"];

export const vendorTypes: string[] = ["Carrier", "Haulier", "Agent"]; // todo get from VendorType enum instead

export const invoiceTypes: Record<string, string> = {
  salesInvoices: "Sales Invoice",
  purchaseInvoices: "Purchase Invoice",
  creditNotes: "Credit Note",
  debitNotes: "Debit Note",
};

export const currencySymbols: Record<string, string> = {
  USD: "$",
  EUR: "€",
  GBP: "£",
};

export const documentTypes: string[] = [
  "Booking Confirmation",
  "VGM",
  "SI",
  "UCR",
  "Purchase Invoice",
  "BL",
  "BL Draft",
  "Other",
];

export const blStatuses: string[] = [
  "SI & VGM SUB",
  "DRAFT SENT",
  "AMENDMENT REQ",
  "AWA REV DRAFT",
  "REV DRAFT SENT",
  "INCORRECT DRAFT SENT",
  "BL APPROVED",
  "OBL SENT",
  "OBL WEB SENT",
  "SWB SENT",
  "TELEX RELEASE",
];

export const transportStatuses: TransportStatus[] = [
  TransportStatus.Pending,
  TransportStatus.Delayed,
  TransportStatus.Diverted,
  TransportStatus.Cancelled,
  TransportStatus.Rebooked,
  TransportStatus.Completed,
];

export const bookingTypes: string[] = ["Export", "Import"];
export const bookingStatuses: BookingStatus[] = [
  BookingStatus.Ongoing,
  BookingStatus.Cancelled,
  BookingStatus.Merged,
  BookingStatus.Completed,
  BookingStatus.All,
];
export const exportBookingTeams: TeamType[] = [
  TeamType.All,
  TeamType.Rockers,
  TeamType.Sharks,
];
export const importBookingTeams: TeamType[] = [TeamType.All, TeamType.Falcons];
export const blTypes: string[] = ["Seaway", "Original"];
export const loadingTypes: string[] = ["SD", "CY"];
export const doorFacingOptions: string[] = ["Cab", "Rear"];
export const haulageTypes: HaulageType[] = [
  HaulageType.Merchant,
  HaulageType.Carrier,
  HaulageType.Combined,
];

export const DEFAULT_BOOKING: Booking = {
  bookingType: bookingTypes[0],
  bookingStatus: bookingStatuses[0],
  bookingTeam: TeamType.Rockers,
  mblStatus: blTypes[0],
  hblStatus: blTypes[0],
  containerType: containerTypes[0],
  loadingTypeExport: loadingTypes[0],
  loadingTypeImport: loadingTypes[0],
  doorFacing: doorFacingOptions[1],
  cargoValue: {
    currency: currencies[0],
  },
} as Booking;
