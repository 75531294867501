import React, { Dispatch, SetStateAction, useState } from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Box,
  useTheme,
  IconButton,
  Typography,
  Tooltip,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Button,
} from "@mui/material";
import {
  InfoOutlined,
  AddOutlined,
  EditOutlined,
  MoreVertOutlined,
  DeleteOutlined,
  FileDownloadOutlined,
  RecentActorsOutlined,
  SendOutlined,
} from "@mui/icons-material";
import UpdateInvoiceDialogue from "../UpdateInvoiceDialogue";
import axios from "axios";
import { sec } from "../../auth/accessToken";
import InvoiceDialogue from "../InvoiceDialogue";
import InvoiceViewer from "../InvoiceViewer";
import AssignmentTurnedInOutlinedIcon from "@mui/icons-material/AssignmentTurnedInOutlined";
import { Booking, BookingStatus, Invoice } from "models/booking.model";
import { currencySymbols, invoiceTypes } from "dataAssets/constants";
import {
  useUserPermissions,
  totalInvoiceValue,
  handleSnackbarClose,
  checkMissingKeys,
} from "utils/utils";
import ConfirmDialogue from "components/ConfirmDialogue";
import { PDFDownloadLink } from "@react-pdf/renderer";
import InvoiceConfirmation from "components/InvoiceConfirmation";
import AlertSnackbar from "components/AlertSnackbar";
import { useGetAuditsQuery } from "state/api";
import AuditLogsDialogue from "components/AuditLogsDialogue";

export interface InvoiceTableProps {
  booking: Booking;
  invoiceType: string;
  refetch: () => void;
}
const InvoiceTable: React.FC<InvoiceTableProps> = ({
  booking,
  invoiceType,
  refetch,
}) => {
  const theme = useTheme();

  const userPermissions = useUserPermissions();

  const missingKeys = checkMissingKeys(booking);

  const [currentInvoice, setCurrentInvoice]: [
    Invoice,
    Dispatch<SetStateAction<Invoice>>
  ] = useState({} as Invoice);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [open, setOpen] = useState<number | null>(null);
  const handleMenuOpen = (
    event: React.MouseEvent<HTMLElement>,
    index: number
  ) => {
    setOpen(index);
    setCurrentInvoice(invoices[index]);
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setOpen(null);
    setAnchorEl(null);
  };

  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const [openConfirmDeletionDialogue, setOpenConfirmDeletionDialogue] =
    useState(false);
  const [openConfirmApprovalDialogue, setOpenConfirmApprovalDialogue] =
    useState(false);

  const [openInvoice, setOpenInvoice] = useState(false);
  const handleClickOpenInvoice = () => {
    setOpenInvoice(true);
    handleMenuClose();
  };
  const handleCloseInvoice = () => {
    setOpenInvoice(false);
  };

  const [openCreateSalesInvoice, setOpenCreateSalesInvoice] = useState(false);
  const handleClickOpenCreateSalesInvoice = () => {
    setOpenCreateSalesInvoice(true);
  };
  const handleCloseCreateSalesInvoice = () => {
    setOpenCreateSalesInvoice(false);
    refetch();
  };

  const [openUpdate, setOpenUpdate] = useState(false);
  const handleClickOpenUpdate = () => {
    setOpenUpdate(true);
    handleMenuClose();
  };
  const handleCloseUpdate = () => {
    setOpenUpdate(false);
    refetch();
  };

  const [openAuditLogs, setOpenAuditLogs] = useState(false);

  const { data: invoicesAuditLogs, refetch: refetchAuditLogs } =
    useGetAuditsQuery(["Invoice", currentInvoice?._id]);

  const invoices = booking[invoiceType as keyof Booking] as Invoice[];

  const updateApproval = async (invoiceId: string) => {
    try {
      const accessToken = await sec.getAccessTokenSilently()();

      await axios.patch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/${invoiceType}/approve/${invoiceId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      refetch();
      refetchAuditLogs();
    } catch (err) {
      console.error(err);
    }
  };

  const createTempSalesInvoice = async () => {
    try {
      const salesInvoice = {
        charges: booking.sellRates,
        numberOfContainers: booking.numberOfContainers,
        bookingNumber: booking.bookingNumber,
        invoiceCurrency: "USD",
        remarks: "",
        customer:
          booking.bookingType === "Export"
            ? booking.consignor
            : booking.consignee,
      };
      const accessToken = await sec.getAccessTokenSilently()();

      const salesInvoiceResponse = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/v1/salesInvoices/`,
        salesInvoice,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      await axios.patch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/bookings/${booking._id}`,
        { salesInvoices: [salesInvoiceResponse.data.id] },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      refetch();
      refetchAuditLogs();
    } catch (err) {
      console.error(err);
    }
  };

  const deleteInvoice = async (invoiceId: string) => {
    try {
      const accessToken = await sec.getAccessTokenSilently()();

      await axios.delete(
        `${process.env.REACT_APP_BASE_URL}/api/v1/${invoiceType}/${invoiceId}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      refetch();
    } catch (err) {
      console.error(err);
    }
  };

  const invoiceSentStatus = async (invoiceId: string) => {
    try {
      const accessToken = await sec.getAccessTokenSilently()();

      await axios.patch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/${invoiceType}/${invoiceId}`,
        { invoiceSent: true },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      refetch();
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Box>
      <Box>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Typography
            sx={{
              letterSpacing: "0.8px",
            }}
          >{`${invoiceTypes[invoiceType]}s`}</Typography>
          {booking.bookingStatus !== BookingStatus.Completed && (
            <Tooltip title={"Create"}>
              <IconButton onClick={handleClickOpenCreateSalesInvoice}>
                <AddOutlined />
              </IconButton>
            </Tooltip>
          )}
        </Box>
        {invoiceType === "salesInvoices" &&
          booking.salesInvoices.length === 0 && (
            <Button
              sx={{
                backgroundColor: theme.palette.secondary[400],
                color: theme.palette.secondary[100],
                "&:hover": {
                  backgroundColor: theme.palette.secondary[500],
                },
              }}
              size="small"
              variant="contained"
              startIcon={<AddOutlined />}
              onClick={() => createTempSalesInvoice()}
            >
              Create Initial Sales Invoice
            </Button>
          )}
        <InvoiceDialogue
          handleClose={handleCloseCreateSalesInvoice}
          open={openCreateSalesInvoice}
          id={booking._id}
          invoices={invoices ?? []}
          booking={booking}
          invoiceType={invoiceType}
        />
      </Box>
      {invoices.length !== 0 && (
        <Table
          sx={{
            "& .MuiTableCell-root": {
              padding: "8px",
              borderBottom: "none",
              color: theme.palette.secondary[100],
            },
            "& .MuiTableHead-root .MuiTableCell-root": {
              fontWeight: "600",
            },
          }}
        >
          <TableHead>
            <TableRow>
              <TableCell>Invoice No</TableCell>
              <TableCell>Invoice Party</TableCell>
              <TableCell>Total</TableCell>
              <TableCell align="center">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody
            sx={{
              "& .MuiTableCell-root": {
                paddingTop: "0px",
                paddingBottom: "0px",
              },
            }}
          >
            {invoices.map((invoice, index) => (
              <TableRow key={index}>
                <TableCell>
                  {invoice.approvedInvoiceNumber ??
                    invoice.performaInvoiceNumber}
                </TableCell>

                <TableCell>
                  {invoice.vendor?.name ?? invoice.customer?.name ?? "N/A"}
                </TableCell>
                <TableCell>{`${
                  currencySymbols[invoice.invoiceCurrency]
                } ${totalInvoiceValue(
                  invoice.charges,
                  invoice.numberOfContainers
                )}`}</TableCell>
                <TableCell align="center">
                  <>
                    <IconButton
                      onClick={(event) => handleMenuOpen(event, index)}
                    >
                      <MoreVertOutlined />
                    </IconButton>
                    <Menu
                      id={`menu-${index}`}
                      anchorEl={anchorEl}
                      open={open === index}
                      onClose={handleMenuClose}
                    >
                      <MenuItem
                        onClick={() => {
                          handleClickOpenInvoice();
                        }}
                      >
                        <ListItemIcon>
                          <InfoOutlined />
                        </ListItemIcon>
                        <ListItemText primary="View" />
                      </MenuItem>
                      {!invoice.approvalPending && (
                        <PDFDownloadLink
                          document={
                            <InvoiceConfirmation
                              invoice={invoice}
                              booking={booking}
                              invoiceType={invoiceType}
                            />
                          }
                          fileName={`${invoice.approvedInvoiceNumber?.replace(
                            /\//g,
                            "-"
                          )}`}
                        >
                          {({ loading }) =>
                            loading ? null : (
                              <>
                                <MenuItem>
                                  <ListItemIcon>
                                    <FileDownloadOutlined />
                                  </ListItemIcon>
                                  <ListItemText primary="Download" />
                                </MenuItem>
                              </>
                            )
                          }
                        </PDFDownloadLink>
                      )}
                      {invoice.approvalPending && (
                        <MenuItem
                          onClick={() => {
                            handleClickOpenUpdate();
                          }}
                        >
                          <ListItemIcon>
                            <EditOutlined />
                          </ListItemIcon>
                          <ListItemText primary="Edit" />
                        </MenuItem>
                      )}
                      {invoice.approvalPending &&
                        userPermissions.includes("delete:invoice") && (
                          <MenuItem
                            onClick={() => {
                              setOpenConfirmDeletionDialogue(true);
                              handleMenuClose();
                            }}
                          >
                            <ListItemIcon>
                              <DeleteOutlined />
                            </ListItemIcon>
                            <ListItemText primary="Delete" />
                          </MenuItem>
                        )}
                      {invoice.approvalPending &&
                        userPermissions.includes("update:approval-invoice") && (
                          <MenuItem
                            onClick={() => {
                              setOpenConfirmApprovalDialogue(true);
                              handleMenuClose();
                            }}
                          >
                            <ListItemIcon>
                              <AssignmentTurnedInOutlinedIcon />
                            </ListItemIcon>
                            <ListItemText primary="Approve" />
                          </MenuItem>
                        )}
                      {!invoice.approvalPending && (
                        <MenuItem
                          onClick={() => {
                            invoiceSentStatus(invoice._id);
                            handleMenuClose();
                          }}
                          disabled={invoice.invoiceSent}
                        >
                          <ListItemIcon>
                            <SendOutlined />
                          </ListItemIcon>
                          <ListItemText
                            primary={`${
                              currentInvoice.invoiceSent ? "Marked" : "Mark"
                            } as sent`}
                          />
                        </MenuItem>
                      )}
                      <MenuItem
                        onClick={() => {
                          setOpenAuditLogs(true);
                          handleMenuClose();
                        }}
                      >
                        <ListItemIcon>
                          <RecentActorsOutlined />
                        </ListItemIcon>
                        <ListItemText primary="Audit Logs" />
                      </MenuItem>
                    </Menu>
                  </>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
      <InvoiceViewer
        handleClose={handleCloseInvoice}
        open={openInvoice}
        invoice={currentInvoice}
        booking={booking}
        invoiceType={invoiceType}
      />
      <UpdateInvoiceDialogue
        handleClose={handleCloseUpdate}
        open={openUpdate}
        invoice={currentInvoice}
        invoiceType={invoiceType}
      />
      <AuditLogsDialogue
        handleClose={() => setOpenAuditLogs(false)}
        open={openAuditLogs}
        auditLogs={invoicesAuditLogs}
      />
      <ConfirmDialogue
        open={openConfirmDeletionDialogue}
        handleClose={() => setOpenConfirmDeletionDialogue(false)}
        onConfirm={() => {
          deleteInvoice(currentInvoice._id);
          setOpenConfirmDeletionDialogue(false);
        }}
        title={"Delete Invoice"}
        content={`Are you sure you want to delete this invoice?`}
      />
      <ConfirmDialogue
        open={openConfirmApprovalDialogue}
        handleClose={() => setOpenConfirmApprovalDialogue(false)}
        onConfirm={() => {
          if (missingKeys.length !== 0) {
            setSnackbarOpen(true);
            return;
          }
          updateApproval(currentInvoice._id);
          setOpenConfirmApprovalDialogue(false);
        }}
        title={"Approve Invoice"}
        content={`Are you sure you want to approve this invoice?`}
      />
      <AlertSnackbar
        open={snackbarOpen}
        handleClose={(_: any, reason: string) =>
          handleSnackbarClose(reason, setSnackbarOpen)
        }
        severity={"warning"}
        message={`Cannot approve this invoice -> Booking is missing required fields: ${missingKeys
          .join(", ")
          .toUpperCase()}`}
      />
    </Box>
  );
};

export default InvoiceTable;
