import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, TextField, Drawer, Switch, MenuItem, Grid } from "@mui/material";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { setInvoicesFilters } from "state";
import dayjs from "dayjs";
import { invoiceTypes } from "dataAssets/constants";
import { TeamType } from "models/booking.model";
import { generateMenuItemsFromArray } from "utils/generateMenuItemsFromArray";

interface SidebarProps {
  open: boolean;
  onClose: () => void;
  teams: TeamType[] | undefined;
}

const Sidebar: React.FC<SidebarProps> = ({ open, onClose, teams }) => {
  const dispatch = useDispatch();

  const {
    invoiceType,
    pendingApproval,
    invoiceOutstanding,
    underDispute,
    startDate,
    endDate,
    bookingTeam,
  } = useSelector((state: any) => state.global.invoicesFilters);

  const handlePendingApprovalToggle = () => {
    dispatch(setInvoicesFilters({ pendingApproval: !pendingApproval }));
    dispatch(
      setInvoicesFilters({
        dateType: pendingApproval ? "approvalDate" : "invoiceDate",
      })
    );
    dispatch(setInvoicesFilters({ underDispute: false }));
  };

  const handleOutstandingToggle = () => {
    dispatch(setInvoicesFilters({ invoiceOutstanding: !invoiceOutstanding }));
  };

  const handleUnderDisputeToggle = () => {
    dispatch(setInvoicesFilters({ underDispute: !underDispute }));
  };

  const handleInvoiceTypeChange = (e: any) => {
    dispatch(setInvoicesFilters({ invoiceType: e.target.value }));
  };

  const handleStartDateChange = (newDate: dayjs.Dayjs | null) => {
    if (newDate && newDate.isValid()) {
      dispatch(setInvoicesFilters({ startDate: newDate.toISOString() }));
    }
  };

  const handleEndDateChange = (newDate: dayjs.Dayjs | null) => {
    if (newDate && newDate.isValid()) {
      dispatch(setInvoicesFilters({ endDate: newDate.toISOString() }));
    }
  };
  const handleBookingTeamChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setInvoicesFilters({ bookingTeam: e.target.value }));
  };

  return (
    <Drawer anchor="right" open={open} onClose={onClose}>
      <Box
        display="flex"
        flexDirection="column"
        gap="1rem"
        p="1rem"
        width="300px"
      >
        <TextField
          select
          label="Invoice Type"
          value={invoiceType}
          onChange={(e) => handleInvoiceTypeChange(e)}
        >
          {Object.entries(invoiceTypes).map(([key, value]) => (
            <MenuItem key={key} value={key}>
              {value}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          select
          label="Team"
          value={bookingTeam}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleBookingTeamChange(e)
          }
        >
          {generateMenuItemsFromArray(teams ?? [])}
        </TextField>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label={`${pendingApproval ? "Creation" : "Approval"} Start`}
            format="DD/MM/YYYY"
            value={dayjs(startDate)}
            onChange={handleStartDateChange}
          />
          <DatePicker
            label={`${pendingApproval ? "Creation" : "Approval"} End`}
            format="DD/MM/YYYY"
            value={dayjs(endDate)}
            onChange={handleEndDateChange}
          />
        </LocalizationProvider>
        <Box>
          <Grid component="label" container alignItems="center" spacing={1}>
            <Grid item sx={{ minWidth: "95px" }}>
              Approved
            </Grid>
            <Grid item>
              <Switch
                checked={pendingApproval}
                onChange={handlePendingApprovalToggle}
              />
            </Grid>
            <Grid item sx={{ minWidth: "95px" }}>
              Unapproved
            </Grid>
          </Grid>
          <Grid component="label" container alignItems="center" spacing={1}>
            <Grid item sx={{ minWidth: "95px" }}>
              No Dispute
            </Grid>
            <Grid item>
              <Switch
                checked={underDispute}
                onChange={handleUnderDisputeToggle}
                disabled={!pendingApproval}
              />
            </Grid>
            <Grid item sx={{ minWidth: "95px" }}>
              Under Dispute
            </Grid>
          </Grid>
          <Grid component="label" container alignItems="center" spacing={1}>
            <Grid item sx={{ minWidth: "95px" }}>
              Settled
            </Grid>
            <Grid item>
              <Switch
                checked={invoiceOutstanding}
                onChange={handleOutstandingToggle}
                disabled={pendingApproval}
              />
            </Grid>
            <Grid item sx={{ minWidth: "95px" }}>
              Outstanding
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Drawer>
  );
};

export default Sidebar;
