import React, { useState } from "react";
import axios from "axios";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Box,
  useTheme,
  IconButton,
  Divider,
  Tooltip,
  CircularProgress,
  Menu,
  MenuItem,
  ListItemText,
} from "@mui/material";
import DocumentViewer from "../DocumentViewer";
import BookingConfirmation from "../BookingConfirmation";
import UpdateTermsAndConditions from "../UpdateTermsAndConditions";
import { PDFDownloadLink } from "@react-pdf/renderer";
import {
  InfoOutlined,
  FileDownloadOutlined,
  DeleteOutlined,
  SpeakerNotesOutlined,
  FormatListNumberedOutlined,
} from "@mui/icons-material";
import UpdateRemarksDialogue from "components/UpdateRemarksDialogue";
import { Booking, StepStatus, TransportSchedule } from "models/booking.model";
import { sec } from "auth/accessToken";
import BookingConfirmationViewer from "components/BookingConfirmationViewer/BookingConfirmationViewer";
import TransportScheduleViewer from "components/TransportScheduleViewer";
import TransportScheduleDocument from "components/TransportScheduleDocument";
import {
  splitTransportScheduleByHauler,
  useUserPermissions,
} from "utils/utils";
import { useGetAuditsQuery } from "state/api";
import { exportSteps } from "dataAssets/actionSteps";

export interface DocumentsTableProps {
  files: any[];
  booking: Booking;
  bookingRefetch: Function;
  filesRefetch: Function;
}

const DocumentsTable: React.FC<DocumentsTableProps> = ({
  files,
  booking,
  bookingRefetch,
  filesRefetch,
}) => {
  const theme = useTheme();
  const userPermissions = useUserPermissions();

  const { refetch: auditLogsRefetch } = useGetAuditsQuery([
    "Booking",
    booking._id,
  ]);

  const [termsAndConditionsOpen, setTermsAndConditionsOpen] = useState(false);
  const handleTermsAndConditionsClickOpen = () => {
    setTermsAndConditionsOpen(true);
  };
  const handleTermsAndConditionsClose = () => {
    setTermsAndConditionsOpen(false);
    bookingRefetch();
  };

  const [openRemarks, setOpenRemarks] = useState(false);
  const handleClickOpenRemarks = () => {
    setOpenRemarks(true);
  };
  const handleCloseRemarks = () => {
    setOpenRemarks(false);
    bookingRefetch();
  };

  const [documentViewerIndex, setDocumentViewerIndex] = useState(0);
  const [openDocument, setOpenDocument] = useState(false);
  const handleClickOpenDocument = () => {
    setOpenDocument(true);
  };
  const handleCloseDocument = () => {
    setOpenDocument(false);
  };

  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);

  const handleMenuClose = () => {
    setMenuAnchor(null);
  };

  const [openBookingConfirmation, setOpenBookingConfirmation] = useState(false);

  const [openTransportSchedule, setOpenTransportSchedule] = useState(false);

  const haulerGroups = splitTransportScheduleByHauler(
    booking.transportSchedule
  );

  const [selectedHaulerTransportSchedule, setSelectedHaulerTransportSchedule] =
    useState<TransportSchedule[]>([]);

  const deleteDocument = async (documentPath: String) => {
    try {
      const accessToken = await sec.getAccessTokenSilently()();

      await axios.delete(
        `${process.env.REACT_APP_BASE_URL}/api/v1/documents/?key=${documentPath}&bookingId=${booking._id}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      filesRefetch();
      auditLogsRefetch();
    } catch (error) {
      console.log(error);
    }
  };

  const isCarrierBookingCompleted = booking.stepsCompleted.some((step) => step.dbRef === exportSteps[0].dbRef && step.status === StepStatus.Completed);
  return (
    <Box>
      <Box mb={"1rem"}>
        <Table
          sx={{
            "& .MuiTableCell-root": {
              padding: "8px",
              borderBottom: "none",
              color: theme.palette.secondary[100],
            },
            "& .MuiTableHead-root .MuiTableCell-root": {
              fontWeight: "600",
            },
          }}
        >
          <TableHead>
            <TableRow>
              <TableCell>Booking Confirmation</TableCell>
              <TableCell>
                <Box
                  display={"grid"}
                  gridTemplateColumns={"repeat(4, 1fr)"}
                  gap={"1rem"}
                >
                  <Box>
                    {isCarrierBookingCompleted &&
                      !!booking.carrierBookingNumber && (
                        <>
                          <Tooltip title="Download Booking Confirmation">
                            <IconButton
                              onClick={(
                                event: React.MouseEvent<HTMLElement>
                              ) => {
                                setMenuAnchor(event.currentTarget);
                              }}
                            >
                              <FileDownloadOutlined />
                            </IconButton>
                          </Tooltip>
                          <Menu
                            anchorEl={menuAnchor}
                            open={Boolean(menuAnchor)}
                            onClose={handleMenuClose}
                          >
                            <MenuItem onClick={handleMenuClose}>
                              <PDFDownloadLink
                                document={
                                  <BookingConfirmation
                                    booking={booking}
                                    showQuotation={true}
                                  />
                                }
                                fileName={`${booking.bookingNumber}_with_rates.pdf`}
                              >
                                {({ loading }) =>
                                  loading ? (
                                    <CircularProgress size={"1rem"} />
                                  ) : (
                                    <ListItemText primary="Including Quotation" />
                                  )
                                }
                              </PDFDownloadLink>
                            </MenuItem>
                            <MenuItem onClick={handleMenuClose}>
                              <PDFDownloadLink
                                document={
                                  <BookingConfirmation
                                    booking={booking}
                                    showQuotation={false}
                                  />
                                }
                                fileName={`${booking.bookingNumber}_without_rates.pdf`}
                              >
                                {({ loading }) =>
                                  loading ? (
                                    <CircularProgress size={"1rem"} />
                                  ) : (
                                    <ListItemText primary="Excluding Quotation" />
                                  )
                                }
                              </PDFDownloadLink>
                            </MenuItem>
                          </Menu>
                        </>
                      )}
                  </Box>
                  <Box>
                    <Tooltip title="View Booking Confirmation">
                      <span>
                        <IconButton
                          onClick={() => setOpenBookingConfirmation(true)}
                          disabled={
                            !isCarrierBookingCompleted ||
                            !booking.carrierBookingNumber
                          }
                        >
                          <InfoOutlined />
                        </IconButton>
                      </span>
                    </Tooltip>
                    <BookingConfirmationViewer
                      handleClose={() => setOpenBookingConfirmation(false)}
                      open={openBookingConfirmation}
                      booking={booking}
                    />
                  </Box>
                  <Box>
                    <Tooltip title="Edit T&Cs">
                      <IconButton onClick={handleTermsAndConditionsClickOpen}>
                        <FormatListNumberedOutlined />
                      </IconButton>
                    </Tooltip>
                    <UpdateTermsAndConditions
                      handleClose={handleTermsAndConditionsClose}
                      open={termsAndConditionsOpen}
                      id={booking._id}
                      termsAndConditions={booking.bookingTermsAndConditions}
                      type={"booking"}
                    />
                  </Box>
                  <Box>
                    <Tooltip title="Edit Remarks">
                      <IconButton onClick={handleClickOpenRemarks}>
                        <SpeakerNotesOutlined />
                      </IconButton>
                    </Tooltip>
                    <UpdateRemarksDialogue
                      handleClose={handleCloseRemarks}
                      open={openRemarks}
                      booking={booking}
                    />
                  </Box>
                </Box>
              </TableCell>
            </TableRow>
            {Object.keys(haulerGroups).map((hauler, index) => (
              <TableRow key={`hauler-${hauler}-${index}`}>
                <TableCell>{`${hauler} - TO`}</TableCell>
                <TableCell>
                  <Box
                    display={"grid"}
                    gridTemplateColumns={"repeat(4, 1fr)"}
                    gap={"1rem"}
                  >
                    <Box>
                      {isCarrierBookingCompleted &&
                        !!booking.carrierBookingNumber && (
                          <PDFDownloadLink
                            key={`transport-schedule-${hauler}-${index}`}
                            document={
                              <TransportScheduleDocument
                                booking={booking}
                                transportSchedule={haulerGroups[hauler]}
                              />
                            }
                            fileName={`TransportSchedule_${hauler}_${booking.bookingNumber}`}
                          >
                            {({ loading }) =>
                              loading ? (
                                <CircularProgress size={"1rem"} />
                              ) : (
                                <Tooltip title="Download Transport Schedule">
                                  <IconButton>
                                    <FileDownloadOutlined />
                                  </IconButton>
                                </Tooltip>
                              )
                            }
                          </PDFDownloadLink>
                        )}
                    </Box>
                    <Box>
                      <Tooltip title="View Transport Schedule">
                        <span>
                          <IconButton
                            onClick={() => {
                              setOpenTransportSchedule(true);
                              setSelectedHaulerTransportSchedule(
                                haulerGroups[hauler]
                              );
                            }}
                            disabled={
                              !isCarrierBookingCompleted ||
                              !booking.carrierBookingNumber
                            }
                          >
                            <InfoOutlined />
                          </IconButton>
                        </span>
                      </Tooltip>
                      <TransportScheduleViewer
                        handleClose={() => setOpenTransportSchedule(false)}
                        open={openTransportSchedule}
                        booking={booking}
                        transportSchedule={selectedHaulerTransportSchedule}
                      />
                    </Box>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableHead>
        </Table>
      </Box>
      <Divider />
      <Box mt={"1rem"}>
        <Table
          sx={{
            "& .MuiTableCell-root": {
              padding: "8px",
              borderBottom: "none",
              color: theme.palette.secondary[100],
            },
            "& .MuiTableHead-root .MuiTableCell-root": {
              fontWeight: "600",
            },
          }}
        >
          <TableHead>
            <TableRow>
              <TableCell>Document</TableCell>
              <TableCell>Document Type</TableCell>
              <TableCell align="center">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {files !== undefined &&
              files.map((file, index) => (
                <TableRow key={`file-${file.Key}-${index}`}>
                  <TableCell>{file.Key.split("/")[2]}</TableCell>
                  <TableCell>{file.Key.split("/")[1]}</TableCell>
                  <TableCell>
                    <Box display={"flex"} justifyContent={"space-around"}>
                      <Tooltip title="View">
                        <IconButton
                          onClick={() => {
                            handleClickOpenDocument();
                            setDocumentViewerIndex(index);
                          }}
                        >
                          <InfoOutlined />
                        </IconButton>
                      </Tooltip>
                      {userPermissions.includes("delete:document") && (
                        <Tooltip title="Delete">
                          <IconButton onClick={() => deleteDocument(file.Key)}>
                            <DeleteOutlined />
                          </IconButton>
                        </Tooltip>
                      )}
                      <DocumentViewer
                        handleClose={handleCloseDocument}
                        open={openDocument}
                        documentPath={files[documentViewerIndex].Key}
                      />
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </Box>
    </Box>
  );
};

export default DocumentsTable;
